<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.content-html {
  width: calc(100% - 50px);
  padding: 50px 25px;
  text-align: left;
  line-height: 25px;
  background-color: #f1f1f1;
}
</style>

<template>
  <div class="v-customer-wxset-views">
    <el-tabs v-model="tab_name" type="card">
      <el-tab-pane label="人脸采集须知" name="人脸采集须知">
        <div v-if="is_edit">
          <div style="margin-top: 10px; margin-bottom: 10px;">
            <el-button type="primary" icon="el-icon-back" size="mini" @click="editWxset(false)">返回</el-button>
          </div>
          <div style="width: 100%; height: 100%; overflow-y: auto; overflow-x: hidden;">
            <div style="width: 100%; height: 100%;">
              <Tinymce ref="TinymceEditor" v-model="localForm.content" :height="450" :upload-request="uploadRequest" />
            </div>
          </div>
          <div style="margin-top: 30px; margin-bottom: 10px;">
            <el-button type="primary" size="small" @click="onSubmitClick">修改</el-button>
            <el-button size="small" @click="onCancelClick">取消</el-button>
          </div>
        </div>
        <div v-else>
          <div style="margin-top: 10px; margin-bottom: 10px;">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="editWxset(true)">编辑</el-button>
          </div>
          <div style="width: 100%; height: 80vh; overflow-y: auto;">
            <div style="width: 100%; height: 100%;">
              <div class="content-html" v-html="wxset_data.content"></div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import APIs from '../../../APIs';
import Tinymce from '@/components/Tinymce';
export default {
  name: 'wxset_capture_notice',
  data: function() {
    return {
      tab_name: '人脸采集须知',
      wxset_data: {},
      is_edit: false,
      localForm: {
        content: ''
      }
    }
  },
  components: {
    Tinymce
  },
  mounted () {
    this.fetchWxset();
  },
  methods: {
    onUploadPromise(file) {
      const that = this;
      return new Promise((resolve, reject) => {
        // 创建form对象
        let formData = new FormData();
        formData.append('file', file);
        that.$http.post(APIs.UPLOAD_FILE, formData)
          .then(({ data }) => {
            if (parseInt(data.code) !== 200) reject(new Error(data.message));
            resolve(data.data);
          })
          .catch((err) => {
            reject(err)
          })
          .then(() => { })
      })
    },
    uploadRequest(file) {
      return this.onUploadPromise(file).then(res => {
        return res.url
      })
    },
    fetchWxset() {
      const self = this;
      self.$http.get(APIs.WXSET_CAPTURE_NOTICE_DATA)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.message);
          self.wxset_data = data.data;
          self.localForm.content = self.wxset_data.content || '';
          // console.log(self.wxset_data);
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { })
    },
    editWxset(e) {
      this.is_edit = e;
      if (e) {
        if (this.wxset_data !== undefined &&
            this.wxset_data !== null &&
            this.wxset_data.content !== undefined &&
            this.wxset_data.content !== null) {
          this.localForm.content = this.wxset_data.content || '';
        } else {
          this.localForm.content = '';
        }
        if (this.$refs.TinymceEditor !== undefined && this.$refs.TinymceEditor !== null) {
          this.$refs.TinymceEditor.setContent(this.localForm.content || '');
        }
      }
    },
    onSubmitClick() {
      const self = this;
      if (self.wxset_data !== null && self.wxset_data.id !== undefined && self.wxset_data.id !== null && parseInt(self.wxset_data.id) > 0) {
        let formData = new FormData();
        formData.append('id', parseInt(self.wxset_data.id));
        formData.append('content', self.localForm.content);
        self.$http.post(APIs.WXSET_CAPTURE_NOTICE_DATA, formData)
          .then(({ data }) => {
            if (parseInt(data.code) !== 200) throw new Error(data.message);
            self.fetchWxset();
            self.editWxset(false);
            self.$message({
              type: 'success',
              message: '更新成功'
            })
          })
          .catch((err) => {
            self.$message({
              type: 'error',
              message: err.message || err.statusText
            })
          })
          .then(() => { })
      } else {
        self.$message({
          type: 'error',
          message: '更新失败，目标不存在'
        })
      }
    },
    onCancelClick() {
      this.editWxset(false);
    }
  }
}
</script>