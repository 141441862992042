<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.el-select-dropdown__item {
  font-weight: 200;
}
/deep/.el-select-dropdown {
  background-color: transparent;
  border: 1px solid blue;
}
/deep/.el-select-dropdown__list {
  padding: 0;
}
/deep/.el-popper[x-placement^="bottom"] {
  margin-top: 0px;
}
/deep/.el-popper .popper__arrow,
/deep/.el-popper .popper__arrow::after {
  display: none;
}
.el-select-dropdown__item:hover {
  background-color: rgba(0, 225, 219, 0.6);
}
</style>

<template>
  <el-form :inline="true" :model="form_data" class="demo-form-inline">
    <el-form-item label="查询用户">
      <el-select v-model="form_data.wxuser_search"
        filterable
        clearable
        placeholder="请选择"
        value-key="openid"
        :filter-method="filterWxuserSearch"
        @visible-change="handleVisibleChange">
        <el-option v-for="item in wxuserSearchs"
                   :key="item.openid"
                   :label="item.openid"
                   :value="item">
          <template>
            <!-- :gutter="50" -->
            <el-row type="flex" justify="center">
              <!-- :span="10"  -->
              <el-col style="width: 240px;">
                <div style="width: 240px;">
                  <span>{{ item.openid }}</span>
                </div>
              </el-col>
              <el-col style="width: 42px;">
                <div style="margin-top: 2px; width: 42px;">
                  <span style="width: 6px; height:30px; display: inline-block;"></span>
                  <img v-if="item.userInfo && item.userInfo.avatar" :src="item.userInfo.avatar" alt="" width="30px" height="30px" style="width: 30px;" />
                  <span v-else style="width: 30px; height:30px; display: inline-block;"></span>
                  <span style="width: 6px; height:30px; display: inline-block;"></span>
                </div>
              </el-col>
              <el-col>
                <span>{{ item.userInfo && item.userInfo.nickname ? item.userInfo.nickname : '' }}</span>
              </el-col>
            </el-row>
          </template>
        </el-option>
      </el-select>
    </el-form-item>
    <span style="margin-left: 5px; margin-right: 5px;"></span>
    <el-form-item label="查询日期">
      <el-date-picker v-model="form_data.time_range"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions">
      </el-date-picker>
    </el-form-item>
    <span style="margin-left: 10px; margin-right: 10px;"></span>
    <el-form-item>
      <el-button type="primary" icon="el-icon-search" @click="$emit('search',form_data)">查询</el-button>
      <el-button type="primary" icon="el-icon-download" @click="$emit('download',form_data)">下载筛选/全部推荐数据</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import APIs from '../../../APIs';
export default {
  name: 'finance-search-form',
  props: ['wxuser'],
  data: function() {
    return {
      pageQueryInfoByWxuser: {
        pageNo: 1,
        pageSize: 1000,
        total: 0
      },
      pageSearchInfoByWxuser: {
        pageNo: 1,
        pageSize: 1000
      },
      wxuserSearchData: [],
      wxuserSearchs: [],
      // 表单数据
      form_data: {
        wxuser_search: null,
        time_range: []
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 3);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近半年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 6);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      } // element-ui带快捷选项的时间picker选项
    }
  },
  mounted () {
    this.pageQueryInfoByWxuser.pageNo = this.pageSearchInfoByWxuser.pageNo;
    this.pageQueryInfoByWxuser.pageSize = this.pageSearchInfoByWxuser.pageSize;
    this.pageQueryInfoByWxuser.total = this.pageSearchInfoByWxuser.total;
    this.wxuserSearchData = [];
    this.wxuserSearchs = [];
    this.form_data.wxuser_search = this.wxuser;
    // const end = new Date();
    // const start = new Date();
    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    // this.form_data.time_range = [this.dataToString(start), this.dataToString(end)];
    this.fetchWxuser(this.pageSearchInfoByWxuser);
  },
  methods: {
    dataToString (date) {
      let year = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      if (month.length === 1) {
        month = '0' + month;
      }
      if (day.length === 1) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    },
    filterWxuserSearch(query) {
      if (query !== '') {
        this.wxuserSearchs = this.wxuserSearchData.filter((item) => {
          let isf = item.openid.toLowerCase().indexOf(query.toLowerCase()) > -1;
          if (!isf) {
            isf = item.userInfo.nickname.indexOf(query) > -1;
          }
          return isf;
        });
      } else {
        this.wxuserSearchs = this.wxuserSearchData;
      }
    },
    handleVisibleChange(visible) {
      if (visible) {
        if (this.wxuserSearchData.length > 0 && this.pageQueryInfoByWxuser.total > 0) {
          if (this.pageQueryInfoByWxuser.pageNo * this.pageQueryInfoByWxuser.pageSize < this.pageQueryInfoByWxuser.total) {
            this.fetchWxuser({
              pageNo: this.pageQueryInfoByWxuser.pageNo + 1,
              pageSize: this.pageQueryInfoByWxuser.pageSize
            });
          }
        } else {
          this.fetchWxuser(this.pageSearchInfoByWxuser);
        }
      }
    },
    fetchWxuser(param) {
      const self = this;
      let formData = new FormData();
      formData.append('pageNo', param.pageNo);
      formData.append('pageSize', param.pageSize);
      self.$http.post(APIs.USERS_DATA, formData)
        .then(({ data }) => {
          if (parseInt(data.code) === 200) {
            let wxuserData = data.data;
            // console.log(wxuserData);
            if (wxuserData !== undefined && wxuserData !== null && wxuserData.list !== undefined && wxuserData.list !== null) {
              self.wxuserSearchData = self.wxuserSearchData.concat(wxuserData.list);
              self.wxuserSearchs = self.wxuserSearchData;
              let pageNoInt = parseInt(wxuserData.pageNo);
              let pageSizeInt = parseInt(wxuserData.pageSize);
              let total = parseInt(wxuserData.total);
              self.pageQueryInfoByWxuser.pageNo = pageNoInt;
              self.pageQueryInfoByWxuser.pageSize = pageSizeInt;
              self.pageQueryInfoByWxuser.total = total;
              if (pageNoInt * pageSizeInt < total) {
                const pageQuery = {
                  pageNo: pageNoInt + 1,
                  pageSize: pageSizeInt
                };
                self.fetchWxuser(pageQuery);
              }
              // console.log(self.wxuserSearchData);
            }
          } else {
            self.$message({
              type: 'error',
              message: '用户查询数据加载失败'
            })
          }
        })
        .catch(() => {
          self.$message({
            type: 'error',
            message: '用户查询数据加载失败'
          })
        })
        .then(() => { self.wxuser_list_loading = false })
    }
  }
}
</script>