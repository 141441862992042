/*
 * 个人信息视图
 * Author: yudong
 * Date: 2018-04-27 11:12:06
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 18:17:11
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>
   .v-admin-userInfo-views {
        margin-left: 100px;
        margin-top: 50px;
        .submit {
            margin-left: 360px;
        }
    }
</style>
<template>
<div class="v-admin-userInfo-views">
     <!-- 用户表单信息 -->
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" :label-position="labelPosition" :inline="true" v-loading="submit_loading">

        <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>

        <el-form-item label="邮箱" prop="email">
            <el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
        </el-form-item><br/>

        <el-form-item label="密码" prop="password">
            <el-input v-model="ruleForm.password" placeholder="请输入密码"></el-input>
        </el-form-item>

        <el-form-item label="是否接受注册邮件的信息" prop="register_notice">
            <el-select v-model="ruleForm.register_notice" placeholder="请选择">
                    <el-option label="是" value="1">是</el-option>
                    <el-option label="否" value="0">否</el-option>
            </el-select>
        </el-form-item><br>

        <el-form-item class="submit">
            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </el-form-item>

    </el-form>

</div>
</template>

<script>
import {
    mapGetters,
    mapMutations
} from 'vuex';

import API_LIST from '../../../API_LIST';

export default {

    name: 'admin-userInfo-views',

    data: function() {
        return {

            labelPosition: 'top',

            submit_loading: false, // 提交表单时的加载框
            ruleForm: {

                _method: 'PUT',

                name: '',

                email: '',

                password: '',

                register_notice: ''

            }, // 表单数据

             rules: { // 表单数据验证规则
                name: [{
                    required: true,
                    message: '请输入姓名',
                    trigger: 'change'
                }],
                email: [
                    {
                        required: true,
                        message: '请输入邮箱',
                        trigger: 'change'
                    },
                    {
                        type: 'email',
                        message: '邮箱格式不合法',
                        trigger: 'change'
                    }
                    ],
                register_notice: [{
                    required: true,
                    message: '请选择是否接受注册邮件信息',
                    trigger: 'change'
                }]
            }
        }
    },

    computed: {

        ...mapGetters('userToken', [
            'getUserInfo'
        ])

    },
    mounted() {
        this.initData(); // 数据初始化
    },
    methods: {

        ...mapMutations('userToken', [
            'setUserInfo'
        ]),

         /**
         * 初始化赋值
         * @return {undefined}
         */
        initData() {
            this.ruleForm.name = this.getUserInfo.name
            this.ruleForm.email = this.getUserInfo.email
            this.ruleForm.register_notice = this.getUserInfo.register_notice ? '1' : '0'
        },

        /**
         * 提交表单
         * @param  {string} name {表单的ref}
         * @return {undefined}
         */
        submitForm(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.dataSubmit()
                } else {
                    this.$message({
                        type: 'error',
                        message: '不满足提交条件!'
                    })
                }
            })
        },
         /**
         * post数据
         * @return {undefined}
         */
         dataSubmit() {
            const self = this
            this.submit_loading = true
            this.$http.post(API_LIST.BYM_USER + '/' + this.getUserInfo.id, this.ruleForm)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.$message.success('保存成功!')
                    this.setUserInfo(data.open_admin)
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err.message || err.statusText
                    })
                })
                .then(() => { this.submit_loading = false })
        }
    }
}
</script>