/*
 * 服务订单列表
 * Author: ljb
 * Date: 2018-04-27 14:37:52
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 16:50:05
 */
<style rel="stylesheet/css" type="text/css" scoped>

</style>

<template>
 <div class="v-admin-order-service-index">
     <el-table  align='center'
                border
                height="600"
                v-loading="loading"
                :data="data">

        <el-table-column prop="api_user.name" label="所属客户" :formatter="hadOrNot"></el-table-column>

        <el-table-column prop="billing_type.type" label="服务名称" :formatter="hadOrNot"></el-table-column>

        <el-table-column prop="trade_no" label="服务单号" :formatter="hadOrNot"></el-table-column>

        <el-table-column prop="call_count" label="调用次数" :formatter="hadOrNot"></el-table-column>

        <el-table-column prop="billing_count" label="计费次数（计月的为购买月数）" :formatter="hadOrNot"></el-table-column>

        <el-table-column prop="service_status" label="服务状态" :formatter="formatData"></el-table-column>

        <el-table-column prop="single_billing" label="单次费用（计月的为每月费用）" :formatter="hadOrNot"></el-table-column>

        <el-table-column prop="amount" label="总服务费用" :formatter="hadOrNot"></el-table-column>

        <el-table-column prop="created_at" label="购买日期" width="180" :formatter="hadOrNot"></el-table-column>

        <el-table-column prop="started_at" label="生效日期" width="180" :formatter="hadOrNot"></el-table-column>

        <el-table-column prop="expired_at" label="失效日期" width="180" :formatter="hadOrNot"></el-table-column>

        <el-table-column label="操作">

            <template slot-scope="scope">
                <el-button type="primary" @click="$emit('edit',scope.row)">修改</el-button>
            </template>

        </el-table-column>

    </el-table>
 </div>
</template>

<script>

export default {
    name: 'order-service-index',
    props: ['loading', 'data'],
    data: function() {
        return { }
    },
    methods: {
        /**
         * 有值或没值的格式化方法
         * @param  {string} cellValue {当前需要判断的值}
         * @return {string} {当前值或者'-'}
         */
        hadOrNot(row, column, cellValue, index) {
            return String(cellValue) || '-'
        },
        /**
         * 格式化数据显示
         * @param  {string} cellValue {当前需要格式化的值}
         * @return {string} {格式化后的值}
         */
        formatData(row, column, cellValue, index) {
            return parseInt(cellValue) === 0 ? '未使用' : '使用中'
        }
    }
}
</script>