/** 密钥管理主视图 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
<div class="v-customer-secret-key-views">
  <el-tabs v-model="tab_name" type="card">
    <el-tab-pane label="密钥管理" name="密钥管理">
      <key-index :api-user="api_user"
                 :loading="loading"
                 v-loading="loading"
                 element-loading-text="请稍后">
      </key-index>
    </el-tab-pane>
  </el-tabs>
</div>
</template>

<script>
import APIs from '../../../APIs';
import { mapGetters } from 'vuex';
import keyIndex from './index-new';
export default {
  name: 'secret-key-views',
  data () {
    return {
      tab_name: '密钥管理',
      api_user: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters('userToken', ['getToken'])
  },
  components: {
    keyIndex
  },
  mounted () {
    this.fetchApiUserMessage()
  },
  methods: {
    fetchApiUserMessage () {
      const self = this;
      self.loading = true;
      self.$http.get(APIs.ADMIN_ACCESS + '?token=' + self.getToken)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.error_message);
          self.api_user = data.data;
        })
        .catch((err) => {
          self.$message({ type: 'error', message: err.message || err.statusText });
        })
        .then(() => { self.loading = false; })
    }
  }
}
</script>