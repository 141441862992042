/**
 * 网址格式化 =====ok.start
 * @return {Object} {格式化网址之后得到的参数对象}
 */
export function formaturl() {
  let url_info = {}
  const url_object = location.href.split('#').shift().split('?').pop()
  url_object.split('&').forEach((url) => {
    let key = url.split('=').shift()
    let value = url.split('=').pop()
    if (key) {
      url_info[key] = value
    }
  })
  return url_info
}
// =====ok.end

/**
 * 图片预加载
 * @param  {array} src_arrs {图片链接数组}
 * @return {array} {加载后的图片数组}
 */
export function imgPreLoad(src_arrs) {
    let imgs = [];
    src_arrs.forEach((src, index) => {
        let img = new Image();
        img.src = src;
        imgs[index] = img;
    })
    return imgs;
};

/**
 * 判断a数组是否包含b数组
 * @param  {array} a {判断的a数组}
 * @param  {array} b {判断的b数组}
 * @return {Boolean} {true代表包含，false代表不包含}
 */
export function isContained(a, b) {
    if (!(a instanceof Array) || !(b instanceof Array)) return false;
    if (a.length < b.length) return false;
    // var aStr = a.toString();
    let aStr = a.toString();
    /// for (var i = 0, len = b.length; i < len; i++) {
    for (let i = 0, len = b.length; i < len; i++) {
        if (aStr.indexOf(b[i]) === -1) return false;
    }
    return true;
}

export function getUploadImage(file, _image) {
    // var reader = new FileReader();
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(event) {
        _image.src = event.target.result;
    }
}