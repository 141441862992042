/* 超管权限菜单配置列表 */
export default [
  {
    name: '/tryon-open/admin',
    icon: 'el-icon-menu',
    text: '总览',
    key: 'staff'
  },
  {
    name: '/tryon-open/admin/customer',
    icon: 'el-icon-service',
    text: '客户管理',
    key: 'secret'
  },
  {
    name: '/tryon-open/admin/service-price',
    icon: 'el-icon-goods',
    text: '服务价格',
    key: 'service'
  },
  {
    name: '/tryon-open/admin/order',
    icon: 'el-icon-menu',
    text: '订单管理',
    key: 'material',
    submenus: [
      {
        name: '/tryon-open/admin/order/service',
        icon: 'el-icon-tickets',
        text: '服务订单'
      },
      {
        name: '/tryon-open/admin/order/recharge',
        icon: 'el-icon-tickets',
        text: '充值订单'
      },
      {
        name: '/tryon-open/admin/order/flow',
        icon: 'el-icon-tickets',
        text: '资金流水'
      }
    ]
  },
  {
    name: '/tryon-open/admin/userInfo',
    icon: 'el-icon-view',
    text: '个人信息',
    key: 'userInfo'
  }
]