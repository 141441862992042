/** 3D素材管理主视图 */
<template>
  <div class="v-customer-material-3D-views">
    <el-tabs
      v-model="tab_name"
      type="card"
      @tab-click="handleClick"
      v-loading="loading"
      element-loading-text="请稍后">
      <el-tab-pane label="新增" name="新增">
        <material-create ref="create" @post="postData"></material-create>
      </el-tab-pane>
      <el-tab-pane label="查看" name="查看">
        <material-index
          :loading="loading"
          :materials="resultG3dAssets.list"
          @edit="get3DMaterial"
          @delete="delete3DMaterial"
        ></material-index>
        <br/>
        <el-pagination
            align="center"
            background
            @size-change="sizePageChanged"
            @current-change="currentPageChanged"
            :current-page.sync="resultG3dAssets.pageNo"
            :page-size="pageQueryInfo.pageSize"
            layout="sizes, prev, pager, next"
            :total="resultG3dAssets.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="修改信息" :visible.sync="show_model" @close="closeDialog">
      <material-edit
        :materialDetail="materialDetail"
        @update="update3DMaterial"
        v-loading="loading"
        element-loading-text="请稍后"
      ></material-edit>
      <div slot="footer"></div>
    </el-dialog>
  </div>
</template>

<script>
import APIs from '../../../../APIs';
import materialCreate from './create-new';
import materialIndex from './index-new';
import materialEdit from './edit-new';
import { mapGetters } from 'vuex'
export default {
  name: 'material-3D-views',
  components: {
    materialCreate,
    materialIndex,
    materialEdit
  },
  data: function() {
    return {
      pageQueryInfo: {
        pageNo: 1,
        pageSize: 10
      },
      pageSearchInfo: {
        pageNo: 1,
        pageSize: 10
      },
      loading: false,
      tab_name: '查看',
      resultG3dAssets: {},
      show_model: false,
      materialDetail: {}
    }
  },
  computed: {
    ...mapGetters('userToken', ['getToken'])
  },
  created () { },
  mounted () {
    if (this.tab_name === '查看') {
      this.fetchG3dMaterial();
    }
  },
  methods: {
    handleClick(tab) {
      if (tab.name === '查看') {
        this.fetchG3dMaterial();
      } else {
        this.resultG3dAssets = {};
      }
    },
    fetchG3dMaterial(param) {
      this.loading = true;
      let queryStr = '';
      if (param !== undefined && param !== null) {
        if (param.pageNo !== undefined && (typeof param.pageNo === 'number') && Number(param.pageNo) > 0) {
          queryStr = queryStr + '&pageNo=' + param.pageNo;
        }
        if (param.pageSize !== undefined && (typeof param.pageSize === 'number') && Number(param.pageSize) > 0) {
          queryStr = queryStr + '&pageSize=' + param.pageSize;
        }
      }
      this.$http
        .get(APIs.GLASSES_3D_ASSET + '?token=' + this.getToken + queryStr)
        .then(({ data }) => {
          // console.log(data);
          if (parseInt(data.code) !== 200) throw new Error(data.error_message);
          this.resultG3dAssets = data.data;
        })
        .catch(err => {
          console.log(err);
          this.$message({
            type: 'error',
            message: '获取3D素材失败'
          })
        })
        .then(() => (this.loading = false));
    },
    sizePageChanged(pageSize) {
      this.pageQueryInfo.pageSize = pageSize;
      this.pageSearchInfo.pageSize = pageSize;
      this.fetchG3dMaterial(this.pageSearchInfo);
    },
    currentPageChanged(pageNo) {
      this.pageSearchInfo.pageNo = pageNo;
      this.pageSearchInfo.pageSize = this.pageQueryInfo.pageSize;
      this.fetchG3dMaterial(this.pageSearchInfo);
    },
    postData(param) {
      if (param !== undefined && param !== null) {
        param.append('token', this.getToken);
      }
      this.loading = true;
      this.$http
        .post(APIs.GLASSES_3D_MATERIAL, param)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.error_message);
          this.$message({ type: 'success', message: '新增素材成功' });
          this.$refs.create.handleReset('formValidate');
          this.tab_name = '查看';
          this.fetchG3dMaterial();
        })
        .catch((err) => {
          console.log(err);
          this.$message({ type: 'error', message: '新增素材失败' });
        })
        .then(() => { this.loading = false });
    },
    closeDialog() {
      this.materialDetail = null;
    },
    get3DMaterial(param) {
      this.show_model = true;
      this.$nextTick(() => (this.materialDetail = param));
    },
    update3DMaterial(id, param) {
      if (param !== undefined && param !== null) {
        param.append('token', this.getToken);
      }
      this.loading = true;
      this.$http
        .post(APIs.GLASSES_3D_MATERIAL + '/' + id, param)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.error_message)
          this.$message({ type: 'success', message: '更新素材成功' });
          this.show_model = false;
          this.fetchG3dMaterial(this.pageSearchInfo);
        })
        .catch((err) => {
          console.log(err);
          this.$message({ type: 'error', message: '更新素材失败' });
        })
        .then(() => { this.loading = false });
    },
    delete3DMaterial(id) {
      this.$confirm('此操作将永久删除该条素材记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        let form_data = new FormData();
        form_data.append('_method', 'DELETE');
        form_data.append('token', this.getToken);
        this.loading = true;
        this.$http
          .post(APIs.GLASSES_3D_MATERIAL + '/' + id, form_data)
          .then(({ data }) => {
            if (parseInt(data.code) !== 200) throw new Error(data.error_message);
            this.$message({ type: 'success', message: '删除素材成功' });
            this.fetchG3dMaterial(this.pageSearchInfo);
          })
          .catch((err) => {
            console.log(err);
            this.$message({ type: 'error', message: '删除素材失败' });
          })
          .then(() => { this.loading = false });
      })
      .catch(() => { })
    }
  }
}
</script>

<style lang="less" rel="stylesheet/less" type="text/less">
.v-customer-material-3D-views {
  .el-dialog {
    width: 35%;
  }
}
</style>