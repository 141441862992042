/*
 * bym-admin首页展示
 * Author: ljb
 * Date: 2018-04-23 11:02:19
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 14:14:54
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.v-admin-home-views { }
</style>
<template>
<div class="v-admin-home-views">
    我是首页
</div>
</template>
<script>

import API_LIST from '../../../API_LIST';

import qs from 'qs';

export default {
    name: 'bym-admin-homepage',
    data () {
        return { }
    },
    mounted () { },
    computed: { },
    methods: { }
}
</script>