<template>
  <div class="upload-container">
    <el-button
      :style="{ background: color, borderColor: color } + '; border-radius: 24px;'"
      icon="el-icon-upload"
      size="mini"
      type="primary"
      @click="dialogVisible=true"
    >
      插入图片
    </el-button>
    <el-dialog :visible.sync="dialogVisible">
      <el-upload
        :http-request="uploadImage"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :multiple="true"
        :file-list="fileList"
        :show-file-list="true"
        class="editor-slide-upload"
        action="#"
        list-type="picture-card"
        drag
        accept="image/*"
      >
        <div class="el-tiptap-upload__text">将图片文件拖到此处或者点击上传</div>
      </el-upload>
      <el-button @click="dialogVisible = false">
        取消
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        确定
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'UploadImage',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    },
    uploadRequest: {
      require: false,
      type: Function,
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: []
    }
  },
  methods: {
    checkAllSuccess() {
      return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
    },
    handleSubmit() {
      const arr = Object.keys(this.listObj).map(v => this.listObj[v])
      if (!this.checkAllSuccess()) {
        this.$message('请等待所有图片上传完成')
        return
      }
      this.$emit('uploadSuccess', arr)
      this.listObj = {}
      this.fileList = []
      this.dialogVisible = false
    },
    handleSuccess(response, file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].hasSuccess = true
          // console.log(this.listObj[objKeyArr[i]])
          return
        }
      }
    },
    handleRemove(file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]]
          return
        }
      }
    },
    async uploadImage(requestOptions) {
      const { file } = requestOptions
      const fileName = file.uid
      let uploadReq = this.uploadRequest
      // const url = await this.readFileDataUrl(file)
      const url = await (uploadReq ? uploadReq(file) : this.readFileDataUrl(file))
      if (url) {
        this.listObj[fileName] = { hasSuccess: false, uid: fileName, url }
      }
    },
    readFileDataUrl(file) {
      const reader = new FileReader()
      return new Promise((resolve, reject) => {
        reader.onload = readerEvent => resolve(readerEvent.target.result)
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    }
  }
}
</script>

<style lang="less" scoped> //scss
.editor-slide-upload {
  margin-bottom: 20px;
  ::v-deep .el-upload--picture-card {
    width: 100%;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
    height: auto;
  }
}
</style>
