/*
 * 充值订单视图
 * Author: ljb
 * Date: 2018-04-27 11:12:06
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 15:11:28
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
<div class="v-admin-order-recharge-views">

    <el-tabs v-model="tab_name" type="card">

        <el-tab-pane label="充值订单" name="充值订单">

            <search-form    :api-users="api_users"
                            @search="confirmSearch"></search-form>

            <!-- 服务订单列表 -->
            <recharge-index :loading="recharge_loading"
                            :data="rechargeIndex"></recharge-index>

            <br>
            <el-pagination  align="center"
                            background
                            @size-change="perPageChange"
                            @current-change="pageChange"
                            :current-page.sync="recharges.current_page"
                            :page-size="query_info.per_page"
                            layout="sizes, prev, pager, next"
                            :total="recharges.total">
            </el-pagination>
            <!-- 分页信息 -->
        </el-tab-pane>

    </el-tabs>

</div>
</template>

<script>

import API_LIST from '../../../../API_LIST';

import qs from 'qs';

import rechargeIndex from './index';
import searchForm from './search-form';

export default {
    name: 'order-recharge-views',
    data: function() {
        return {
            tab_name: '充值订单',
            recharge_loading: false, // loading
            query_info: {
                page: 1,
                per_page: 20,
                api_user_id: '',
                time_range: ''
            },
            search_info: {
                page: 1,
                per_page: 20
            },
            api_users: [], // api_user_lists
            recharges: {} // 充值订单信息
        }
    },
    components: {
        rechargeIndex,
        searchForm
    },
    created () {
        this.fetchApiUser()
    },
    mounted () {
        this.fetchRechargeIndex(this.query_info)
    },
    computed: {
        rechargeIndex() {
            return this.recharges.data || []
        }
    },
    methods: {

        /**
         * 页码改变
         * @param  {string} pageNumber {当前页码}
         * @return {undefined}
         */
        pageChange(pageNumber) {
            this.search_info.page = pageNumber
            this.search_info.per_page = this.query_info.per_page
            this.fetchRechargeIndex(this.search_info)
        },

        /**
         * 每页显示数改变
         * @param  {string} per_page {当前每页显示数}
         * @return {undefined}
         */
        perPageChange(per_page) {
            this.search_info.per_page = per_page
            this.query_info.per_page = per_page
            this.fetchRechargeIndex(this.search_info)
        },

        /**
         * 确认搜索
         * @param  {string} balance_type {财务类型}
         * @param  {array} time_range   {时间区间}
         * @return {undefined}
         */
        confirmSearch({ api_user_id, time_range }) {
            this.query_info.api_user_id = api_user_id || ''
            this.query_info.time_range = time_range || []
            this.search_info = JSON.parse(JSON.stringify(this.query_info))
            this.fetchRechargeIndex(this.query_info)
        },

        /**
         * 获取api使用者的列表
         * @return {undefined}
         */
        fetchApiUser() {
            this.$http.get(API_LIST.BYM_RECHARGE + '/create')
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.api_users = data.api_users
                    })
                    .catch((err) => {
                        this.$message({
                            type: 'error',
                            message: err.message || err.statusText
                        })
                    })
        },
        /**
         * 获取充值订单列表
         * @param  {Object} param {搜索的参数}
         * @return {undefined}
         */
        fetchRechargeIndex(param) {
            this.recharge_loading = true
            const query_str = qs.stringify(param)
            this.$http.get(API_LIST.BYM_RECHARGE + '?' + query_str)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.recharges = data.recharges
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err.message || err.statusText
                    })
                })
                .then(() => { this.recharge_loading = false })
        }
    }
}
</script>