/*
 * 充值订单列表
 * Author: ljb
 * Date: 2018-04-25 10:49:30
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 15:16:29
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

    .table-header {
        background-color: #f2f2f2;
    }

</style>
<template>
    <el-table   :data="data"
                border
                height="600"
                v-loading="loading">
        <el-table-column    v-for="(header,index) in table_headers"
                            class="table-header"
                            :key="index"
                            :prop="header.type"
                            :label="header.label"
                            :formatter="header.formatter"
                            :width="header.width">
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    name: 'recharge-index',
    props: ['data', 'loading'],
    data: function() {
        return {
            table_headers: [
                {
                    type: 'api_user.name',
                    label: '所属客户',
                    width: ''
                },
                {
                    type: 'biz_no',
                    label: '充值单号',
                    width: ''
                },
                // {
                //     type: 'has_invoice',
                //     label: '是否要开发票',
                //     width: '',
                //     formatter: function(row, column, cellValue, index){
                //         return parseInt(cellValue) === 0 ? '未开' : '已开';
                //     }
                // },
                {
                    type: 'pay_status',
                    label: '是否支付',
                    width: '',
                    formatter: function(row, column, cellValue, index) {
                        return parseInt(cellValue) === 0 ? '未完成支付' : '已经支付'
                    }
                },
                {
                    type: 'total_amount',
                    label: '充值金额（元）',
                    width: ''
                },
                {
                    type: 'remark',
                    label: '备注',
                    width: '',
                    formatter: function(row, column, cellValue, index) {
                        return cellValue || '-'
                    }
                },
                {
                    type: 'created_at',
                    label: '交易日期',
                    width: '180'
                }
            ]
        }
    },
    methods: {}
}
</script>