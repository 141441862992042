/*
 * 忘记密码的form
 * Author: ljb
 * Date: 2018-04-26 18:18:02
 * Last Modified by: ljb
 * Last Modified time: 2018-04-26 18:28:28
 */
<style scoped>

</style>
<template>
<el-form ref="forget_form" status-icon :model="form_data" :rules="ruleInline">

    <el-form-item prop="email" label="邮箱">
        <el-input v-model="form_data.email" placeholder="请输入邮箱"></el-input>
    </el-form-item>

    <el-form-item>
        <el-button type="primary" class="forget-btn" @click="handleSubmit('forget_form')" round>提交</el-button>
    </el-form-item>

</el-form>
</template>

<script>
export default {
    name: 'forget-form',
    data () {
        return {
            form_data: {
                email: '' // 用户名
            },
            // 登录表单的校验
            ruleInline: {
                email: [
                    { required: true, message: '请填写邮箱', trigger: 'change' },
                    { type: 'email', message: '邮箱格式不正确', trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        // 提交表单的方法
        handleSubmit (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$emit('post', this.form_data)
                } else {
                    this.$message({
                        type: 'error',
                        message: '账号或密码未填！'
                    })
                }
            })
        }
    }
}
</script>