/*
 * 客户列表
 * Author: LuoQianJun
 * Date: 2018-04-25 10:53:05
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 10:25:19
 */

<style lang="less" rel="stylesheet/less" type="text/less">
    .admin-customer-index {
        .el-table th>.cell , .el-table .cell {
            text-align: center;
        }
    }
</style>

<template>
    <div class="admin-customer-index" >
        <el-table align="center" :data="customers" style="width:100%"  border  v-loading="loading" height="600">
             <el-table-column  v-for="(header,index) in table_headers"
                            class="table-header"
                            :key="index"
                            :prop="header.type"
                            :label="header.label"
                            :formatter="header.formatter"
                            :width="header.width">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="success" v-if="String(scope.row.expired_at) === '1970-01-02 00:00:00'" @click="handleEnabled(scope.row)" >启用</el-button>
                    <el-button type="danger" v-else @click="handleDisabled(scope.row)">停用</el-button>
                </template>
            </el-table-column>
            <!-- <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column> -->
    </el-table>
    </div>
</template>

<script>
export default {
    name: 'admin-customer-index',
    props: ['customers', 'loading'],
    data: function() {
        return {
            table_headers: [
                {
                    type: 'name',
                    label: '客户姓名',
                    width: '',
                    fixed: 'left'
                },
                {
                    type: 'email',
                    label: '邮箱',
                    width: ''
                },
                {
                    type: 'tel',
                    label: '电话',
                    formatter: function(row, column, cellValue, index) {
                        return cellValue || '-'
                    },
                    width: ''
                },
                {
                    type: 'company',
                    label: '公司',
                    formatter: function(row, column, cellValue, index) {
                        return cellValue || '-'
                    },
                    width: ''
                },
                {
                    type: 'website',
                    label: '公司网址',
                    formatter: function(row, column, cellValue, index) {
                        return cellValue || '-'
                    },
                    width: ''
                },
                {
                    type: 'token',
                    label: 'token',
                    width: ''
                },
                {
                    type: 'call_count',
                    label: '接口调用次数',
                    width: ''
                },
                {
                    type: 'ips',
                    label: 'ip地址',
                    formatter: function(row, column, cellValue, index) {
                        return cellValue || '-'
                    },
                    width: ''
                },
                {
                    type: 'hosts',
                    label: '域名',
                    formatter: function(row, column, cellValue, index) {
                       return cellValue.length > 0 ? cellValue.join(' , ') : '-'
                    },
                    width: ''
                },
                {
                    type: 'balance',
                    label: '客户余额',
                    width: ''
                },
                {
                    type: 'activated_at',
                    label: '激活时间',
                    formatter: function(row, column, cellValue, index) {
                        return cellValue.split(' ').shift() || '-'
                    },
                    width: ''
                },
                {
                    type: 'expired_at',
                    label: '到期时间',
                    formatter: function(row, column, cellValue, index) {
                        return cellValue.split(' ').shift() || '-'
                    },
                    width: ''
                }
            ]
        }
    },
    methods: {
        /**
         * 客户删除
         * @param  {Object} record {传入的对象}
         * @return {undefined}
         */
        handleDelete(record) {
            this.$emit('delete', record.id)
        },
        /**
         * 客户编辑
         * @param  {Object} record {传入的对象}
         * @return {undefined}
         */
        handleEdit(record) {
            this.$emit('edit', record)
        },
        /**
         * 客户启用
         * @param  {Object} record {传入的对象}
         * @return {undefined}
         */
        handleEnabled(record) {
            this.$emit('enabled', record)
        },
        /**
         * 客户禁用
         * @param  {Object} record {传入的对象}
         * @return {undefined}
         */
        handleDisabled(record) {
            this.$emit('disabled', record)
        }
    }
}

</script>