/*
 * 服务订单视图
 * @Author: ljb
 * @Date: 2018-04-27 11:12:06
 * @Last Modified by: ljb
 * @Last Modified time: 2018-04-27 17:05:50
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
<div class="v-admin-order-service-views">
    <el-tabs v-model="tab_name" type="card">

        <el-tab-pane label="服务订单" name="服务订单">
            <!-- 搜索表单 -->
            <search-form    :api-users="api_users"
                            @search="confirmSearch"></search-form>

            <!-- 服务订单列表 -->
            <service-index  :loading="service_list_loading"
                            :data="serviceIndex"
                            @edit="reviseService"></service-index>

            <br>
            <el-pagination  align="center"
                            background
                            @size-change="perPageChange"
                            @current-change="pageChange"
                            :current-page.sync="user_services.current_page"
                            :page-size="query_info.per_page"
                            layout="sizes, prev, pager, next"
                            :total="user_services.total">
            </el-pagination>
            <!-- 分页信息 -->
        </el-tab-pane>

    </el-tabs>

    <!-- 修改服务信息的弹框 -->
    <el-dialog  title="修改服务信息"
                :visible.sync="show_dialog"
                width="600px"
                v-loading="dialog_loading"
                @close="closeDialog">

        <service-edit   :service-detail="service_detail"
                        @update="updateService"></service-edit>

    </el-dialog>
</div>
</template>

<script>

import API_LIST from '../../../../API_LIST';

import qs from 'qs';

import serviceIndex from './index';
import serviceEdit from './edit';
import searchForm from './search-form';

export default {
    name: 'order-service-views',
    data: function() {
        return {
            dialog_loading: false, // 弹框的loading
            show_dialog: false, // 弹框显隐控制
            tab_name: '服务订单', // 默认的tab
            query_info: {
                page: 1,
                per_page: 20,
                api_user_id: '',
                service_status: '',
                time_range: []
            }, // 查询信息
            search_info: {
                page: 1,
                per_page: 20
            }, // 分页信息
            service_detail: null,
            service_list_loading: false, // 列表更新时的loading
            api_users: [], // api使用者数组
            user_services: {} // 购买服务的订单
        }
    },
    components: {
        serviceIndex,
        serviceEdit,
        searchForm
    },
    created () {
        this.fetchApiUser()
    },
    mounted () {
        this.fetchOrderList(this.query_info)
    },
    computed: {
        serviceIndex() {
            return this.user_services.data || []
        }
    },
    methods: {

        /**
         * 页码改变
         * @param  {string} pageNumber {当前页码}
         * @return {undefined}
         */
        pageChange(pageNumber) {
            this.search_info.page = pageNumber
            this.search_info.per_page = this.query_info.per_page
            this.fetchOrderList(this.search_info)
        },

        /**
         * 每页显示数改变
         * @param  {string} per_page {当前每页显示数}
         * @return {undefined}
         */
        perPageChange(per_page) {
            this.search_info.per_page = per_page
            this.query_info.per_page = per_page
            this.fetchOrderList(this.search_info)
        },

        /**
         * 确认搜索
         * @param  {string} balance_type {财务类型}
         * @param  {array} time_range   {时间区间}
         * @return {undefined}
         */
        confirmSearch({ api_user_id, service_status, time_range }) {
            this.query_info.api_user_id = api_user_id || ''
            this.query_info.service_status = service_status || ''
            this.query_info.time_range = time_range || []
            this.search_info = JSON.parse(JSON.stringify(this.query_info))
            this.fetchOrderList(this.query_info)
        },

        /**
         * 关闭弹框后的回调
         * @return {undefined}
         */
        closeDialog() {
            this.service_detail = null
        },

        /**
         * 修改客户的服务状态
         * @param  {Object} row {服务的对象}
         * @return {type} {description}
         */
        reviseService(row) {
            this.show_dialog = true
            this.$nextTick(() => { this.service_detail = row })
        },
        /**
         * 更新服务
         * @param  {Object} param {需要更新的对象参数}
         * @return {undefined}
         */
        updateService(id, param) {
            this.dialog_loading = true
            this.$http.post(API_LIST.BYM_SERVICE + '/' + id, param)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.$message({
                        message: '修改成功！',
                        type: 'success'
                    })
                    this.show_dialog = false
                    this.fetchOrderList(this.search_info)
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err.message || err.statusText
                    })
                })
                .then(() => { this.dialog_loading = false })
        },

        /**
         * 获取api使用者的列表
         * @return {undefined}
         */
        fetchApiUser() {
            this.$http.get(API_LIST.BYM_RECHARGE + '/create')
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.api_users = data.api_users
                    })
                    .catch((err) => {
                       console.log(err)
                        this.$message({
                            type: 'error',
                            message: err.message || err.statusText
                        })
                    })
        },

        /**
         * 获取订单列表
         * @param  {Object} param {搜索列表的参数}
         * @return {undefined}
         */
        fetchOrderList(param) {
            this.service_list_loading = true
            const query_str = qs.stringify(param)
            this.$http.get(API_LIST.BYM_SERVICE + '?' + query_str)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.user_services = data.api_user_services
                })
                .catch((err) => {
                    console.log(err)
                    this.$message({
                        type: 'error',
                        message: err.message || err.statusText
                    })
                })
                .then(() => { this.service_list_loading = false })
        }
    }
}
</script>