import { render, staticRenderFns } from "./views-new.vue?vue&type=template&id=2ee2e890&"
import script from "./views-new.vue?vue&type=script&lang=js&"
export * from "./views-new.vue?vue&type=script&lang=js&"
import style0 from "./views-new.vue?vue&type=style&index=0&id=2ee2e890&prod&lang=less&rel=stylesheet%2Fless&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports