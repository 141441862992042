/*
 *
 * Author: ljb
 * Date: 2018-04-26 11:10:26
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 10:25:32
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>
<el-form :inline="true" :model="form_data" class="demo-form-inline">
    <el-form-item label="客户名称">
         <el-input v-model="form_data.name" placeholder="请输入客户名称" @keyup.enter.native="$emit('search',form_data)"></el-input>
    </el-form-item>

    <el-form-item>
        <el-button type="primary" @click="$emit('search',form_data)">查询</el-button>
    </el-form-item>
</el-form>
</template>

<script>
export default {
    name: 'finance-search-form',
    data: function() {
        return {
            // 表单数据
            form_data: {
                name: ''
            }
        }
    },
    methods: {}
}
</script>