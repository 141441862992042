<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>
  <div class="v-customer-wxset-views">
    <el-tabs v-model="tab_name" type="card">
      <el-tab-pane label="我的" name="我的">
        <div>-</div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'wxset_me',
  data: function() {
    return {
      tab_name: '我的',
      wxset_data: {}
    }
  },
  components: { },
  mounted () { },
  methods: { }
}
</script>