/** 财务流水列表 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.table-header {
  background-color: #f2f2f2;
}
.el-table-column-expand {
  width: 100px;
  color: #99a9bf;
  padding: 0 12px 0 0;
  line-height: 36px;
  font-weight: 700;
  display: inline-block;
}
</style>

<template>
  <el-table :data="data"
            border
            height="600"
            v-loading="loading">
    <!--
    <el-table-column v-for="(header,index) in table_headers"
                    class="table-header"
                    :key="index"
                    :prop="header.type"
                    :label="header.label"
                    :formatter="header.formatter"
                    :width="header.width">
    </el-table-column>
    -->
    <!--
    订单号，调用次数（分成功失败次数），计费描述、总计费，下载xls的月度数据报表操作
    订单号，调用次数，计费次数 (计月的为购买月数)，单次费用 (计月的为每月费用)，总服务费用
    流水订单，流水日期，API次数，计费描述，计费公式，总计费，xls下载
    -->
    <el-table-column prop="serial_no" label="流水订单号" align="center" width="150px"></el-table-column>
    <el-table-column label="流水日期" align="center" width="180px">
      <template slot-scope="{ row }">
        <span>{{ row.cost_start_at }}</span>
        <br />
        <span> 至 </span>
        <br />
        <span>{{ row.cost_end_at }}</span>
      </template>
    </el-table-column>
    <el-table-column label="API次数" align="center" width="180px">
      <template slot-scope="{ row }">
        <span style="color: green; font-weight: bold;">成功次数：</span>
        <span v-if="row.result_ok_count" style="color: green; font-weight: bold;"> {{ row.result_ok_count }} </span>
        <span v-else> - </span>
        <br />
        <span style="color: red; font-weight: bold;">失败次数：</span>
        <span v-if="row.result_fail_count" style="color: red; font-weight: bold;"> {{ row.result_fail_count }} </span>
        <span v-else> - </span>
      </template>
    </el-table-column>
    <el-table-column label="计费描述" align="center">
      <template slot-scope="{ row }">
        <span v-for="(item, index) in costDescs(row.cost_descs, row.cost_formula)" :key="index">
          <span v-html="item"> </span>
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="cost_formula" label="计费公式" align="center"></el-table-column>
    <el-table-column prop="cost_amount" label="总计费(元)" align="center" width="130px"></el-table-column>
    <el-table-column label="流水xls" align="center" width="100px">
      <template slot-scope="{ row }">
        <span v-if="row" >
          <el-button v-if="row.cost_flows_file" type="primary" circle icon="el-icon-download" size="mini" @click="handleEdit(row.cost_flows_file)"></el-button>
          <span v-else style="color: red;"> 结算审核中，请稍后操作 </span>
        </span>
        <span v-else> - </span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
    name: 'flow-index',
    props: ['data', 'loading'],
    data: function() {
        return {
            table_headers: [
                {
                    type: 'serial_no',
                    label: '流水单号',
                    width: '200'
                },
                {
                    type: 'request_type',
                    label: 'API类型',
                    width: '120'
                },
                {
                    type: 'request_id',
                    label: '请求 ID',
                    width: ''
                },
                {
                    type: 'request_ip',
                    label: '请求 IP',
                    width: ''
                },
                {
                    type: 'result_status',
                    label: '调用状态',
                    width: '60'
                },
                {
                    type: 'result_ok_count',
                    label: '调用成功',
                    width: '60'
                },
                {
                    type: 'result_fail_count',
                    label: '调用错误',
                    width: '60'
                },
                {
                    type: 'created_at',
                    label: '请求时间',
                    width: '160'
                }
            ]
        }
    },
    methods: {
      handleEdit (e) {
        window.location.href = e;
        /// let a = document.createElement('a');
        /// a.setAttribute('href', e);
        /// a.setAttribute('target', '_blank');
        /// a.setAttribute('download', '');
        /// a.click();
      },
      costDescs(e1, e2) {
        let descs = [];
        try {
          let jsonObj = JSON.parse(e1);
          if (typeof jsonObj === 'object') {
            for (let i = 0; i < jsonObj.length; i++) {
              descs.push(jsonObj[i]);
              if (i < jsonObj.length - 1) {
                descs.push('<br />');
              }
            }
          } else {
            descs.push(e2);
          }
        } catch (e) {
          descs.push(e2);
        }
        return descs;
      }
    }
}
</script>