/*
 * 客户管理视图
 * Author: ljb
 * Date: 2018-04-27 11:12:06
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 14:52:58
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>
    <!-- 管理内容区子路由视图 -->
    <router-view></router-view>
</template>

<script>
export default {
    name: 'admin-order-views',
    data: function () {
        return { }
    },
    methods: {

    }
}
</script>