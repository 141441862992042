/*
 * 试戴云服务重置密码页面
 * Author: ljb
 * Date: 2018-03-26 10:18:23
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 18:12:47
 */
<style lang="less" rel="stylesheet/less" type="text/less">
.v-reset-index {
  width: 100%;
  height: 100%;
  padding-top: 10%;
  background-image: url('http://try-on-api.gz.bcebos.com/open-admin%2Fglasses_login_img.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #fff;
  box-sizing: border-box;
  .reset-content {
    width: 400px;
    min-height: 300px;
    max-height: 600px;
    margin: 0 auto;
    box-shadow: 0px 0px 10px 1px #cecece;
    border-radius: 30px;
    transition: all .3s 0s ease;
    overflow: hidden;
    background-color: rgba(0, 0, 0, .5);
    /*重置密码框样式*/
    .reset-box {
      position: relative;
      width: 100%;
      height: 350px;
      padding: 40px;
      box-sizing: border-box;
      h1 {
        line-height: 80px;
        margin-bottom: 20px;
        text-align: center;
      }
      .form-input {
        & > input {
          padding-left: 40px;
          border-radius: 30px;
        }
        & > .el-input__prefix {
          left: 16px;
        }
      }
      .click-btn {
        position: relative;
        margin-top: 22px;
        .reset {
          position: absolute;
          width: 120px;
          left: 30px;
        }
        .go-back {
          position: absolute;
          width: 120px;
          right: 30px;
        }
      }
    }
    /*重置密码框样式 END*/
  }
}
</style>

<template>
  <div class="v-reset-index">
    <div class="reset-content" v-loading="loading_show">
      <transition name="el-zoom-in-top">
        <div class="reset-box">
          <h1>重置密码</h1>
          <reset-form @post="resetPasswordPost"></reset-form>
        </div>
      </transition>
    </div>
  </div>
</template>

<script type="text/babel">
import API_LIST from '../../API_LIST.js'
// import { mapActions, mapGetters, mapMutations } from 'vuex'
import { formaturl } from '../../utils/utils.js'
import resetForm from './reset_form'
export default {
  data () {
    return {
      show_dialog: false, // 是否显示弹框
      dialog_loading: false, // 弹框的loading
      loading_show: false, // 是否显示loading框
      token: '',
      expired: ''
    }
  },
  components: {
    resetForm
  },
  mounted () {
    const url_param = formaturl()
    if (!url_param || !url_param.token || !url_param.expired) return
    this.getTokenAndExpired(url_param.token, url_param.expired)
  },
    methods: {
        getTokenAndExpired (token, expired) {
            this.token = token
            this.expired = expired
        },
        /**
         * 重置密码的post请求
         * @return {undefined}
         */
        resetPasswordPost (param) {
          this.dialog_loading = true
          let new_obj = {
            token: this.token,
            expired: this.expired,
            password: param.password,
            password_confirmation: param.password_confirmation
          }
          this.$http.post(API_LIST.RESET_PASSWORD, new_obj)
            .then(({ data }) => {
              if (parseInt(data.code) !== 0) throw new Error(data.error)
              this.$message({
                  type: 'success',
                  duration: 600,
                  message: '重置密码成功！'
              })
              this.goToLogin()
            })
            .catch((err) => {
              this.$message({
                  type: 'error',
                  message: err.message || err.statusText
              })
            })
            .then(() => { this.dialog_loading = false })
        },
        /**
         * 返回登录首页
         * @return {undefined}
         */
        goToLogin () {
            if (API_LIST.APP_DEBUG) {
                setTimeout(function () {
                    window.location.href = location.origin + '/dev-open#/login'
                }, 500)
            } else {
                setTimeout(function () {
                    window.location.href = location.origin + '/open-admin#/login'
                }, 500)
            }
        }
    }
}
</script>
