/* 超管权限视图 */
<style scoped>

</style>

<template>
  <!-- 管理内容区子路由视图 -->
  <router-view></router-view>
</template>

<script>
export default {
  name: 'admin-views',
  data: function() {
    return {}
  },
  methods: {}
}
</script>