var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.data,"border":"","height":"600"}},[_c('el-table-column',{attrs:{"prop":"openid","label":"微信openid","align":"center","width":"145px"}}),_c('el-table-column',{attrs:{"label":"微信头像","align":"center","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.userInfo && row.userInfo.avatar)?_c('img',{staticClass:"wxavatar",attrs:{"src":row.userInfo.avatar,"alt":""}}):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"微信昵称","align":"center","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.userInfo && row.userInfo.nickname)?_c('span',[_vm._v(_vm._s(row.userInfo.nickname))]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"姓名","align":"center","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.userInfo && row.userInfo.username)?_c('span',[_vm._v(_vm._s(row.userInfo.username))]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"性别","align":"center","width":"60px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.userInfo && row.userInfo.gender && row.userInfo.gender === 0)?_c('span',[_vm._v("保密")]):(row.userInfo && row.userInfo.gender && row.userInfo.gender === 1)?_c('span',[_vm._v("男")]):(row.userInfo && row.userInfo.gender && row.userInfo.gender === 2)?_c('span',[_vm._v("女")]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"体重","align":"center","width":"60px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.userInfo && row.userInfo.weight)?_c('span',[_vm._v(_vm._s(row.userInfo.weight)+" kg")]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"籍贯(省市)","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.userInfo && row.userInfo.province && row.userInfo.city && row.userInfo.district)?_c('span',[_c('label',[_vm._v(_vm._s(row.userInfo.province ? row.userInfo.province : '*')+" / "+_vm._s(row.userInfo.city ? row.userInfo.city : '*')+" / "+_vm._s(row.userInfo.district ? row.userInfo.district : '*'))])]):_c('span',[_c('label',[_vm._v("-")])])]}}])}),_c('el-table-column',{attrs:{"label":"详细地址","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.userInfo && row.userInfo.address)?_c('span',[_vm._v(_vm._s(row.userInfo.address))]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"手机号码","align":"center","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.userInfo && row.userInfo.phone)?_c('span',[_vm._v(_vm._s(row.userInfo.phone))]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"推荐数据","align":"center","span":"2"}},[_c('el-table-column',{attrs:{"label":"总量","align":"center","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.analysis_count && row.analysis_count > 0)?_c('span',[_vm._v(" "+_vm._s(row.analysis_count)+" ")]):_c('span',[_vm._v("0")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.analysis_count && row.analysis_count > 0)?_c('span',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search","size":"mini"},on:{"click":function($event){return _vm.searchAnalysis(row)}}},[_vm._v("查看")]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-download","size":"mini"},on:{"click":function($event){return _vm.downloadAnalysis(row)}}},[_vm._v("下载")])],1):_c('span',[_vm._v("-")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }