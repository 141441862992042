/*
 * 注册表单
 * Author: ljb
 * Date: 2018-04-26 17:56:07
 * Last Modified by: ljb
 * Last Modified time: 2018-04-26 18:09:32
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
<el-form ref="register_form" status-icon :model="form_data" :rules="register_ruleInline" label-width="80px" >
    <el-form-item label="意向产品" prop="interests">
        <el-checkbox-group v-model="form_data.interests">
            <el-checkbox-button v-for="(service,index) in tryon_services" :label="service" :key="index">{{service}}</el-checkbox-button>
        </el-checkbox-group>
    </el-form-item>

    <el-form-item label="姓名" prop="name">
        <el-input v-model="form_data.name" placeholder="请输入您的真实姓名"></el-input>
    </el-form-item>

    <el-form-item label="邮箱" prop="email">
        <el-input v-model="form_data.email" placeholder="请输入邮箱"></el-input>
    </el-form-item>

    <el-form-item label="电话" prop="tel">
        <el-input v-model="form_data.tel" placeholder="请输入联系电话"></el-input>
    </el-form-item>

    <el-form-item label="公司" prop="company">
        <el-input v-model="form_data.company" placeholder="请输入您当前所在公司"></el-input>
    </el-form-item>

    <el-form-item label="网址" prop="website">
        <el-input v-model="form_data.website" placeholder="请输入您公司的网站"></el-input>
    </el-form-item>

    <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="form_data.password" placeholder="请输入密码"></el-input>
    </el-form-item>

    <el-form-item label="确认密码" prop="password_confirmation">
        <el-input type="password" v-model="form_data.password_confirmation" placeholder="请确认您的密码"></el-input>
    </el-form-item>

    <el-form-item size="large">
        <el-button round @click="$emit('change')">返回</el-button>
        <el-button type="primary" round @click="handleSubmit('register_form')">注册</el-button>
    </el-form-item>
</el-form>
</template>

<script>

import {
    validatePassCheck,
    validatePhone
} from '../../validate' // 引入校验文件

export default {
    name: 'register-form',
    data () {
        return {
            tryon_services: [
                '2.5D眼镜试戴',
                'web3D眼镜试戴'
            ],
            form_data: {
                interests: [],
                name: '',
                email: '',
                tel: '',
                company: '',
                website: '',
                password: '',
                password_confirmation: ''
            },

            // 注册表单的校验
            register_ruleInline: {
                interests: [
                    { required: true, message: '意向产品是必填的', trigger: 'change' }
                ],
                name: [
                    { required: true, message: '用户名是必填的', trigger: 'change' }
                ],
                email: [
                    { required: true, message: '邮箱是必填的', trigger: 'change' },
                    { type: 'email', message: '邮箱格式不合法', trigger: 'change' }
                ],
                tel: [
                    { required: true, message: '电话是必填的', trigger: 'change' },
                    { validator: validatePhone, message: '电话格式不合法', trigger: 'change' }
                ],
                company: [
                    { required: true, message: '公司是必填的', trigger: 'change' }
                ],
                website: [
                    { type: 'url', message: '不合法网址', trigger: 'change' }
                ],
                password: [
                    { required: true, message: '密码是必填的', trigger: 'change' },
                    { min: 6, message: '密码长度不能小于6位', trigger: 'change' }
                ],
                password_confirmation: [
                    { required: true, message: '必须确认密码', trigger: 'change' },
                    { validator: validatePassCheck, trigger: 'change', pwd_info: this }
                ]
            }
        }
    },
    methods: {
        // 提交表单的方法
        handleSubmit (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$emit('post', this.form_data)
                } else {
                    this.$message({
                        type: 'error',
                        message: '账号或密码未填！'
                    })
                }
            })
        }
    }
}
</script>