/*
 * 资金流水列表
 * Author: ljb
 * Date: 2018-04-25 10:49:30
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 15:09:24
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

    .table-header {
        background-color: #f2f2f2;
    }

</style>
<template>
    <el-table   :data="data"
                border
                height="600"
                v-loading="loading">
        <el-table-column    v-for="(header,index) in table_headers"
                            class="table-header"
                            :key="index"
                            :prop="header.type"
                            :label="header.label"
                            :formatter="header.formatter"
                            :width="header.width">
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    name: 'flow-index',
    props: ['data', 'loading'],
    data: function() {
        return {
            table_headers: [
                {
                    type: 'api_user.name',
                    label: '所属客户',
                    width: ''
                },
                {
                    type: 'biz_no',
                    label: '流水单号',
                    width: ''
                },
                {
                    type: 'created_at',
                    label: '交易日期',
                    width: '180'
                },
                {
                    type: 'remark',
                    label: '摘要',
                    width: ''
                },
                {
                    type: 'description',
                    label: '类型',
                    width: ''
                },
                {
                    type: 'amount',
                    label: '交易金额（元）',
                    width: ''
                },
                {
                    type: 'balance',
                    label: '账户余额（元）',
                    width: ''
                },
                {
                    type: 'balance_type',
                    label: '交易类型',
                    formatter: function(row, column, cellValue, index) {
                        return parseInt(cellValue) === 1 ? '充值' : '支出'
                    },
                    width: ''
                }
            ]

        }
    },
    methods: {}
}
</script>