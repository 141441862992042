// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols

const toolbar = [
  'bold italic underline strikethrough formatselect fontsizeselect fontselect forecolor backcolor',
  'removeformat blockquote alignleft aligncenter alignright outdent indent hr bullist numlist pagebreak link image media undo redo',
  'searchreplace subscript superscript anchor insertdatetime table emoticons codesample code charmap preview fullscreen'
]

export default toolbar
