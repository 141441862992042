/** 管理后台 - 首页 - 左侧边导航栏列表 */
<style lang="less" rel="stylesheet/less" type="text/less">
.v-side-menu {
  &.el-menu {
    border-right: none;
  }
}
</style>
<template>
  <el-menu :default-active="getSelectedMenu"
           :default-openeds="getOpenedMenu"
           :unique-opened="true"
           class="v-side-menu"
           @select="routeTo"
           background-color="#545c64"
           text-color="#fff"
           active-text-color="#ffd04b">
    <div v-for="( menu , index ) in getMenus" :key="index">
      <el-menu-item :index="menu.name" v-if="!menu.submenus">
        <i :class="menu.icon"></i>
        <span slot="title">{{ menu.text }}</span>
      </el-menu-item>
      <el-submenu :index="menu.name" v-else>
        <template slot="title">
          <i :class="menu.icon"></i>
          <span>{{ menu.text }}</span>
        </template>
        <el-menu-item v-for="(submenu, index) in menu.submenus" :key="index" :index="submenu.name">{{ submenu.text }}</el-menu-item>
      </el-submenu>
    </div>
  </el-menu>
</template>
<script>
/// import API_LIST from 'src/API_LIST'
import { mapGetters, mapMutations } from 'vuex'
export default {
  data () {
    return {
      DEFAULT_BIG_SPAN: 4,
      is_accordion: true,
      permission_nodes: []
    }
  },
  computed: {
    ...mapGetters('menu', [
      'getMenus',
      'getSelectedMenu',
      'getOpenedMenu',
      'getMenuTitle'
    ]),
    iconSize () {
      let big_size = 24
      let small_size = 20
      return this.spanLeft === this.DEFAULT_BIG_SPAN ? small_size : big_size
    }
  },
  methods: {
    ...mapMutations('menu', ['setSelectedMenu']),
    /**
     * 路由跳转
     * @param  {string} path {菜单的name，也同样是跳转地址}
     * @return {undefined}
     */
    routeTo (path) {
      const self = this
      switch (path) {
        case '/sdk':
          window.open('/open-doc/')
          break
        default:
          self.setSelectedMenu(path)
          self.$router.push({ path: path })
          break
      }
    }
  },
  mounted () { }
}
</script>