/*
 * 服务价格列表
 * Author: LuoQianJun
 * Date: 2018-05-02 09:26:11
 * Last Modified by: LuoQianJun
 * Last Modified time: 2018-05-02 15:06:40
 */

<style lang="less" rel="stylesheet/less" type="text/less">

    .admin-bym-service-price-index {
        .el-table th>.cell , .el-table .cell {
            text-align: center;
        }
    }

</style>

<template>

    <div class="admin-bym-service-price-index" >

        <el-table align="center" :data="billingTypes" style="width:100%"  border  v-loading="loading" height="600">

            <el-table-column  prop="service_id"  label="服务产品类型" :formatter="getServiceType"></el-table-column>

             <el-table-column   v-for="(header,index) in table_headers"
                                class="table-header"
                                :key="index"
                                :prop="header.type"
                                :label="header.label"
                                :formatter="header.formatter"
                                :width="header.width">
            </el-table-column>

            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>

    </el-table>

    </div>

</template>

<script>

export default {
    name: 'admin-bym-service-price-index',
    props: ['billingTypes', 'loading', 'services'],
    data: function() {
        return {
             table_headers: [
                {
                    type: 'type',
                    label: '服务产品名称',
                    width: ''
                },
                {
                    type: 'charge',
                    label: '服务产品价格',
                    width: ''
                },
                {
                    type: 'remark',
                    label: '服务产品简介',
                    width: ''
                },
                {
                    type: 'created_at',
                    label: '创建时间',
                    width: ''
                },
                {
                    type: 'updated_at',
                    label: '更新时间',
                    width: '',
                    formatter: function(row, column, cellValue, index) {
                        return cellValue || '-'
                    }
                }
            ]
        }
    },
    methods: {
        /**
         * 服务价格删除
         * @param  {Object} record {传入的对象}
         * @return {undefined}
         */
        handleDelete(record) {
            this.$emit('delete', record.id)
        },
        /**
         * 服务价格编辑
         * @param  {Object} record {传入的对象}
         * @return {undefined}
         */
        handleEdit(record) {
            this.$emit('edit', record)
        },

        /**
         * 获取服务价格类型
         * @return {undefined}
         */
        getServiceType(row, column) {
            for (let i = 0; i < this.services.length; i++) {
                if (parseInt(row.service_id) === parseInt(this.services[i].id)) {
                    return this.services[i].name
                }
            }
            return '-'
        }
    }
}
</script>