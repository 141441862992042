/*
 * 重置密码表单
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
  <el-form ref="reset_form" status-icon :model="form_data" :rules="reset_ruleInline">
    <el-form-item prop="password">
      <el-input placeholder="请输入密码" type="password" class="form-input" v-model="form_data.password">
        <template slot="prefix">
          <img :src="password_icon" class="icon">
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password_confirmation">
      <el-input placeholder="请输入确认密码" type="password" class="form-input" v-model="form_data.password_confirmation">
        <template slot="prefix">
          <img :src="password_icon" class="icon">
        </template>
      </el-input>
    </el-form-item>
    <el-form-item class="click-btn">
      <el-button type="primary" class="reset" @click="handleSubmit('reset_form')">确定</el-button>
      <el-button type="primary" class="go-back" @click="goToLogin()">返回</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { validatePassCheck } from '../../validate' // 引入校验文件
import API_LIST from '../../API_LIST.js'
export default {
  name: 'register-form',
  data () {
    return {
      password_icon: require('../../assets/icons/password.svg'),
      form_data: {
        password: '',
        password_confirmation: ''
      },
      // 注册表单的校验
      reset_ruleInline: {
        password: [
          { required: true, message: '密码是必填的', trigger: 'change' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'change' }
        ],
        password_confirmation: [
          { required: true, message: '必须确认密码', trigger: 'change' },
          { validator: validatePassCheck, trigger: 'change', pwd_info: this }
        ]
      }
    }
  },
  methods: {
    // 提交表单的方法
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$emit('post', this.form_data)
        } else {
          this.$message({
            type: 'error',
            message: '不符合提交条件！'
          })
        }
      })
    },
    // 跳转到登录页面
    goToLogin () {
      if (API_LIST.APP_DEBUG) {
        window.location.href = location.origin + '/dev-open#/login'
      } else {
        window.location.href = location.origin + '/open-admin#/login'
      }
    }
  }
}
</script>