/* 2D素材列表 */
<style lang="less" rel="stylesheet/less" type="text/less">
.customer-material-2D-index {
  .el-table th>.cell , .el-table .cell {
    text-align: center;
  }
  .el-table td {
    padding: 5px 0;
  }
  .imgcss {
    height: 80px;
    margin: 3px;
  }
  .imgcss:hover {
    border: 1px dashed black;
    border-radius: 6px;
    margin: 2px;
  }
}
</style>

<template>
  <div class="customer-material-2D-index" >
    <el-table align="center" :data="materials" style="width:100%" border v-loading="loading">
      <el-table-column prop="name" label="素材名称"></el-table-column>
      <el-table-column prop="uuid" label="素材编码"></el-table-column>
      <el-table-column prop="frame_img" label="镜框">
        <template slot-scope="scope">
          <img :src="scope.row.frame_img" alt="" class="imgcss">
        </template>
      </el-table-column>
      <el-table-column label="镜框宽高" width="110">
        <template slot-scope="scope">
          {{ scope.row.frame_width  }} * {{ scope.row.frame_height }}
        </template>
      </el-table-column>
      <!--
      <el-table-column prop="temple_img" label="镜腿">
        <template slot-scope="scope">
          <img :src="scope.row.temple_img" alt="" class="imgcss">
        </template>
      </el-table-column>
      <el-table-column label="镜腿宽高" width="110">
        <template slot-scope="scope">
          {{ scope.row.temple_width  }} * {{ scope.row.temple_height  }}
        </template>
      </el-table-column>
      -->
      <el-table-column prop="created_at" label="创建时间" width="100"></el-table-column>
      <el-table-column prop="updated_at" label="更新时间" width="100"></el-table-column>
      <el-table-column label="操作" width="175">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'admin-material-2D-index',
  props: ['materials', 'loading'],
  data: function() {
    return { }
  },
  methods: {
    /** 2D素材删除 */
    handleDelete(record) {
      this.$emit('delete', record.id)
    },
    /** 2D素材编辑 */
    handleEdit(record) {
      this.$emit('edit', record)
    }
  }
}
</script>