/* 财务管理主视图 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>
  <div class="v-customer-analysis-views">
    <el-tabs v-model="tab_name" type="card">
      <el-tab-pane label="推荐数据" name="推荐数据">
        <search-form :wxuser='wxuserSearch' @search="confirmSearch" @download="confirmDownload"></search-form>
        <table-index :data="analysisData"
                    :loading="analysis_list_loading"></table-index>
        <br />
        <el-pagination  align="center"
                        background
                        @size-change="perPageChangeByAnalysisData"
                        @current-change="pageChangeByAnalysisData"
                        :current-page.sync="analysis_data.pageNo"
                        :page-size="pageQueryInfoByAnalysis.pageSize"
                        layout="sizes, prev, pager, next"
                        :total="analysis_data.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import APIs from '../../../APIs';
import searchForm from './search-form';
import tableIndex from './analysis-table';
import { mapGetters, mapMutations } from 'vuex';
import { getUuid, getTimeId } from '../../../utils/filename.js';
export default {
  name: 'analysis-index',
  data: function() {
    return {
      tab_name: '推荐数据',
      pageQueryInfoByAnalysis: {
        pageNo: 1,
        pageSize: 20,
        wxuser_search: null,
        time_range: []
      },
      pageSearchInfoByAnalysis: {
        pageNo: 1,
        pageSize: 20
      },
      analysis_list_loading: false,
      analysis_data: {}
    }
  },
  components: {
    searchForm,
    tableIndex
  },
  mounted () {
    // console.log('mounted', this.getWxuserSearch);
    this.pageQueryInfoByAnalysis.wxuser_search = this.getWxuserSearch;
    this.setWxuserSearch(null);
    // const end = new Date();
    // const start = new Date();
    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    // this.pageQueryInfoByAnalysis.time_range = [this.dataToString(start), this.dataToString(end)];
    this.fetchAnalysis(this.pageQueryInfoByAnalysis);
    // var fileName1 = getUuid(8, 12)
    // var fileName2 = getTimeId(8, 12)
    // console.log(fileName1, 'fileName1', fileName2, 'fileName2')
  },
  computed: {
    ...mapGetters('wxuserData', ['getWxuserSearch']),
    analysisData() {
      return this.analysis_data.list || [];
    },
    wxuserSearch() {
      return this.getWxuserSearch || this.pageQueryInfoByAnalysis.wxuser_search || null;
    }
  },
  methods: {
    ...mapMutations('wxuserData', ['setWxuserSearch']),
    dataToString (date) {
      let year = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      if (month.length === 1) {
        month = '0' + month;
      }
      if (day.length === 1) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    },
    /** 页码改变 */
    pageChangeByAnalysisData(pageNo) {
      this.pageSearchInfoByAnalysis.pageNo = pageNo;
      this.pageSearchInfoByAnalysis.pageSize = this.pageQueryInfoByAnalysis.pageSize;
      this.fetchAnalysis(this.pageSearchInfoByAnalysis);
    },
    /** 每页显示数改变 */
    perPageChangeByAnalysisData(pageSize) {
      this.pageQueryInfoByAnalysis.pageSize = pageSize;
      this.pageSearchInfoByAnalysis.pageSize = pageSize;
      this.fetchAnalysis(this.pageSearchInfoByAnalysis);
    },
    /** 确认搜索 */
    confirmSearch({ wxuser_search, time_range }) {
      this.pageQueryInfoByAnalysis.wxuser_search = wxuser_search || null;
      this.pageQueryInfoByAnalysis.time_range = time_range || [];
      this.fetchAnalysis(this.pageQueryInfoByAnalysis);
    },
    confirmDownload({ wxuser_search, time_range }) {
      const wxuserSearch = wxuser_search || null;
      const timeRange = time_range || [];
      const self = this;
      let fileName = 'analysis_data.xls'; // 文件名
      let formData = new FormData();
      if (wxuserSearch !== undefined && wxuserSearch !== null) {
        formData.append('user', wxuserSearch.openid);
        fileName = wxuserSearch.openid + '_analysis_data.xls'; // 文件名
      } else {
        fileName = getTimeId(8, 12) + '_analysis_data.xls'; // 文件名
      }
      if (timeRange !== null && timeRange.length === 2) {
        formData.append('time_start', timeRange[0]);
        formData.append('time_end', timeRange[1]);
      }
      self.$http.post(APIs.ANALYSIS_XLS, formData, { responseType: 'blob' })
        .then((response) => {
          // console.log(response);
          if (parseInt(response.status) !== 200) throw new Error('下载失败');
          let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' }); // 创建Blob对象
          if (window.navigator.msSaveOrOpenBlob) { // 对于IE浏览器使用msSaveOrOpenBlob方法
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            let a = document.createElement('a');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.setAttribute('target', '_blank');
            a.setAttribute('download', fileName);
            a.click();
          }
        })
        .catch((err) => {
          /// self.$message({
          ///   type: 'error',
          ///   message: err.message || err.statusText
          /// })
          console.log(err.message || err.statusText);
          self.$message({
            type: 'error',
            message: '下载失败'
          })
        })
        .then(() => { })
    },
    /** 财务列表 */
    fetchAnalysis(param) {
      const self = this;
      self.analysis_list_loading = true;
      let formData = new FormData();
      if (self.pageQueryInfoByAnalysis.wxuser_search !== undefined && self.pageQueryInfoByAnalysis.wxuser_search !== null) {
        formData.append('user', self.pageQueryInfoByAnalysis.wxuser_search.openid);
      }
      if (self.pageQueryInfoByAnalysis.time_range !== null && self.pageQueryInfoByAnalysis.time_range.length === 2) {
        formData.append('time_start', self.pageQueryInfoByAnalysis.time_range[0]);
        formData.append('time_end', self.pageQueryInfoByAnalysis.time_range[1]);
      }
      formData.append('pageNo', param.pageNo);
      formData.append('pageSize', param.pageSize);
      self.$http.post(APIs.ANALYSIS_DATA, formData)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.message);
          self.analysis_data = data.data;
          // console.log(self.analysis_data);
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.analysis_list_loading = false })
    }
  }
}
</script>