/*
 * 服务订单列表
 * @Author: yudong
 * @Date: 2018-04-25 09:26:11
 * @Last Modified by: ljb
 * @Last Modified time: 2018-04-27 14:43:16
 */
<style rel="stylesheet/css" type="text/css" scoped>

</style>

<template>
 <div class="v-customer-order-index">
     <el-table  align='center'
                border
                height="600"
                v-loading="loading"
                :data="data">
        <el-table-column v-for="(list,index) in lists"
                        :key="index"
                        :prop="list.type"
                        :label="list.label"
                        :formatter="list.formatter"
                        :width="list.width"
                        >
        </el-table-column>

    </el-table>
 </div>
</template>

<script>

export default {

    name: 'order-index',

    props: ['loading', 'data'],

    data () {
        return {
            lists: [
                {
                    type: 'billing_type.type',

                    label: '服务名称',

                    width: ''

                },
                {
                    type: 'trade_no',

                    label: '订单号',

                    width: ''
                },
                {
                    type: 'call_count',

                    label: '调用次数',

                    width: '',

                    formatter: function (row, column, cellValue, index) {
                        return cellValue || '-'
                    }
                },
                {
                    type: 'billing_count',

                    label: '计费次数（计月的为购买月数）',

                    width: '',

                    formatter: function (row, column, cellValue, index) {
                        return cellValue || 0
                    }
                },
                {
                    type: 'service_status',

                    label: '服务状态',

                    width: '',

                    formatter: function (row, column, cellValue, index) {
                        return parseInt(cellValue) === 0 ? '未使用' : '使用中'
                    }
                },
                {
                    type: 'single_billing',

                    label: '单次费用（计月的为每月费用）',

                    width: ''
                },
                {
                    type: 'amount',

                    label: '总服务费用',

                    width: ''
                },
                {
                    type: 'created_at',

                    label: '购买日期',

                    width: ''
                },
                {
                    type: 'started_at',

                    label: '生效日期',

                    width: ''
                },
                {
                    type: 'expired_at',

                    label: '失效日期',

                    width: ''
                }
            ]
        }
    },
    methods: {

    }
}
</script>