/*
 * 购买服务
 * Author: ljb
 * Date: 2018-04-26 13:41:37
 * Last Modified by: ljb
 * Last Modified time: 2018-04-28 15:57:37
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
<el-form ref="order_form" status-icon :model="form_data" :rules="ruleInline" label-width="80px">

    <el-form-item label="选择服务" prop="billing_type_id">
        <el-tooltip class="item" effect="dark" content="计次服务初次订购不会扣费，会根据调用次数扣费，计月服务则会在首次订购时进行扣费，请务必了解" placement="top">
            <el-select v-model="form_data.billing_type_id" clearable placeholder="请选择">
                <el-option v-for="(billingType) in billingTypes"
                            :key="billingType.id"
                            :label="billingType.type"
                            :value="billingType.id">
                </el-option>
            </el-select>
        </el-tooltip>
    </el-form-item>

    <el-form-item label="购买月数" prop="months" v-if="can_select_month">

        <el-input-number v-model="form_data.months" :min="1" :max="12" label="购买的月份数"></el-input-number>

    </el-form-item>

    <el-form-item :label="total_cash_info">

        <span>{{ needPay + '元' }}</span>

    </el-form-item>

    <el-form-item>
        <el-button type="primary" @click="handleSubmit('order_form')" round>确认订购</el-button>
    </el-form-item>

</el-form>
</template>

<script>
const CAN_SELECT_MONTHS = [12, 15] // 可以按月购买的产品id
export default {
    name: 'order-create',
    props: ['billingTypes', 'balance'],
    data () {
        return {
            total_cash_info: '服务费用',
            can_select_month: false, // 是否可以选择月
            form_data: {
                billing_type_id: '',
                months: 1
            },
            // 表单验证
            ruleInline: {
                billing_type_id: [
                    { required: true, message: '必须选择服务产品', trigger: 'change' }
                ]
            }
        }
    },
    watch: {
        'form_data.billing_type_id': function () {
            const selected_billing_id = this.form_data.billing_type_id || ''

            if (CAN_SELECT_MONTHS.indexOf(parseInt(selected_billing_id)) !== -1) {
                this.can_select_month = true
                this.total_cash_info = '服务费用'
            } else {
                this.can_select_month = false
                this.total_cash_info = '单次费用'
            }
        }
    },
    computed: {
        // 需要支付金额
        needPay () {
            let need_pay = 0.00
            const selected_billing_id = this.form_data.billing_type_id || ''

            let months = this.form_data.months

            const selected_billing = this.billingTypes.filter((billingType) => billingType.id === selected_billing_id)[0]

            if (!selected_billing) return need_pay

            if (CAN_SELECT_MONTHS.indexOf(parseInt(selected_billing_id)) !== -1) {
                need_pay = parseFloat(selected_billing.charge) * months
            } else {
                need_pay = selected_billing.charge
            }
            return need_pay
        }
    },
    methods: {

        /**
         * 提交表单的方法
         * @param  {string} name {form表单绑定的id}
         * @return {undefined}
         */
        handleSubmit (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.showConfirmModal()
                } else {
                    this.$message({
                        type: 'error',
                        message: '不满足提交条件！'
                    })
                }
            })
        },

        /**
         * 确定模态框
         * @return {undefined}
         */
        showConfirmModal () {
            const text = '您当前余额为' + this.balance + '元，确定订购？'
            this.$confirm(text, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let param = {
                        billing_type_id: this.form_data.billing_type_id
                    }
                    if (this.can_select_month) {
                        param.months = this.form_data.months
                    }
                    this.$emit('post', param)
                }).catch(() => { })
         }
     }
}
</script>