export default {
  namespaced: true,
  state: {
    wxuser_search: null
  },
  mutations: {
    setWxuserSearch (state, wxuser) {
      state.wxuser_search = wxuser
    }
  },
  actions: {
    toSetWxuserSearch ({ state, commit, rootState }, wxuser) {
      commit('setWxuserSearch', wxuser)
    }
  },
  getters: {
    getWxuserSearch (state) {
      return state.wxuser_search || null
    }
  }
}