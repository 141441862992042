/*
 * 试戴云服务后台登录页面
 * @Author: ljb
 * @Date: 2018-03-26 10:18:23
 * @Last Modified by: ljb
 * @Last Modified time: 2018-04-27 13:40:39
 */
<style lang="less" rel="stylesheet/less" type="text/less">

    .v-admin-login-index {
        width: 100%;
        height: 100%;
        padding-top: 10%;
        background-image: url('https://gz.bcebos.com/v1/beauty-mirror/web_admin/bg_1.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        color: #fff;
        box-sizing: border-box;
        .login-content {
            width: 400px;
            min-height: 300px;
            max-height: 600px;
            margin: 0 auto;
            box-shadow: 0px 0px 10px 1px #cecece;
            border-radius: 30px;
            transition: all .3s 0s ease;
            overflow: hidden;
            &.register {
                width: 550px;
            }
            /*登录框样式*/
            .login-box {
                position: relative;
                width: 100%;
                height: 350px;
                padding: 40px;
                box-sizing: border-box;
                h1 {
                    line-height: 80px;
                    margin-bottom: 20px;
                    text-align: center;
                    img {
                        width: 50%;
                    }
                }
                .form-input {
                    & > input {
                        padding-left: 40px;
                        border-radius: 30px;
                    }
                    & > .el-input__prefix {
                        left: 16px;
                    }
                }

                /*登录按钮*/
                .login-btn {
                    width: 100%;
                    margin-top: 10px;
                }
                /*登录按钮 END*/
            }
            /*登录框样式 END*/
        }
    }

</style>
<template>
    <div class="v-admin-login-index">
        <div class="login-content" v-loading="loading_show">
            <!-- element过度动画 -->
            <transition name="el-zoom-in-top">
                <!-- 登录框 -->
                <div class="login-box">
                    <h1>试戴云服务后台</h1>
                    <login-form @post="loginRequest"></login-form>
                </div>
            </transition>

        </div>

    </div>
</template>
<script type="text/babel">
import API_LIST from '../../API_LIST.js'
import { mapActions, mapMutations } from 'vuex'
import loginForm from './login_form'
export default {
    data() {
        return {
            loading_show: false, // 是否显示loading框
            img_arrs: [] // 预加载的图片数组
        }
    },
    components: {
        loginForm
    },
    mounted() { },
    methods: {
        // action的引用
        ...mapActions('userToken', [
            'toSetToken',
            'toSetUserInfo'
        ]),
        ...mapMutations('menu', [
            'setMenus',
            'clearCache'
        ]),
        /**
         * 登录的请求
         * @return {undefined}
         */
        loginRequest(param) {
            this.loading_show = true
            const self = this
            this.clearCache()
            this.setMenus('admin')
            this.$http.post(API_LIST.BYM_LOGIN, param)
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.toSetToken(data.token)
                        this.toSetUserInfo(data.admin)
                        this.$message({
                            type: 'success',
                            duration: 600,
                            message: '登录成功！'
                        })
                        setTimeout(self.jumpToHomePage, 600)
                    })
                    .catch((err) => {
                        console.log(err)
                        this.$message({
                            type: 'error',
                            message: err.message || err.statusText
                        })
                    })
                    .then(() => { this.loading_show = false })
        },
        /**
         *  登陆后，转跳到首页
         */
        jumpToHomePage () {
            this.$router.push({
                path: '/tryon-open/admin'
            })
        }
    }
}
</script>