/** 财务流水列表 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.table-header {
  background-color: #f2f2f2;
}
.el-table-column-expand {
  width: 100px;
  color: #99a9bf;
  padding: 0 12px 0 0;
  line-height: 36px;
  font-weight: 700;
  display: inline-block;
}
.maskimg {
  max-width: 180px; /* 最大宽度为父容器宽度 */
  max-height: 180px; /* 最大高度为父容器高度 */
  width: auto; /* 宽度自适应 */
  height: auto; /* 高度自适应 */
  display: block; /* 图片作为块级元素显示 */
  margin: auto;
}
.mask-original {
  max-width: 160px; /* 最大宽度为父容器宽度 */
  max-height: 160px; /* 最大高度为父容器高度 */
}
.mask-processed {
  max-width: 120px; /* 最大宽度为父容器宽度 */
  max-height: 120px; /* 最大高度为父容器高度 */
}
.maskicon {
  width: 36px;
  height: 36px;
}
.wxavatar {
  width: 50px;
  height: 50px;
}
</style>

<template>
  <el-table :data="data" border height="600" v-loading="loading">
    <el-table-column type="expand" width="50px">
      <template slot-scope="props">
        <div v-if="props.row.user">
          <div class="el-table-column-expand">微信openid</div>
          <div v-if="props.row.user && props.row.user.openid" style="display: inline-block">
            <span>{{ props.row.user.openid }}</span>
          </div>
          <div v-else style="display: inline-block">
            <span> - </span>
          </div>
        </div>
        <div>
          <div class="el-table-column-expand">微信头像</div>
          <div v-if="props.row.user && props.row.user.userInfo && props.row.user.userInfo.avatar" style="display: inline-block">
            <img :src="props.row.user.userInfo.avatar" alt="" mode="widthFix" class="wxavatar">
          </div>
          <div v-else style="display: inline-block">
            <span> - </span>
          </div>
        </div>
        <div>
          <div class="el-table-column-expand">微信昵称</div>
          <div v-if="props.row.user && props.row.user.userInfo && props.row.user.userInfo.nickname" style="display: inline-block">
            <span>{{ props.row.user.userInfo.nickname }}</span>
          </div>
          <div v-else style="display: inline-block">
            <span> - </span>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="人脸图像" align="center" span="2">
      <el-table-column label="原始图" align="center" width="180px">
        <template slot-scope="{ row }">
          <span v-if="row.original_image">
            <img :src="row.original_image" alt="" mode="widthFix" class="maskimg mask-original">
          </span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="结果图" align="center" width="150px">
        <template slot-scope="{ row }">
          <span v-if="row.processed_image">
            <img :src="row.processed_image" alt="" mode="widthFix" class="maskimg mask-processed">
          </span>
          <span v-else> - </span>
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column label="脸型信息" align="center" span="8">
      <el-table-column label="脸型" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.face_type_value">{{ row.face_type_value }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="下巴角度" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.jaw_angle">{{ row.jaw_angle }} {{ row.jaw_angle_unit }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="下巴宽度" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.jaw_width">{{ row.jaw_width }} {{ row.jaw_width_unit }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="下颌角度数" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.mandible_angle">{{ row.mandible_angle }} {{ row.mandible_angle_unit }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="下颌角宽度" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.mandible_length">{{ row.mandible_length }} {{ row.mandible_length_unit }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="鼻梁宽度" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.nose_bridge_width">{{ row.nose_bridge_width }} {{ row.nose_bridge_width_unit }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="鼻翼宽度" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.nose_width">{{ row.nose_width }} {{ row.nose_width_unit }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="颧骨宽度" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.zygoma_length">{{ row.zygoma_length }} {{ row.zygoma_length_unit }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column label="口罩匹配度" align="center" span="5">
      <el-table-column label="801-N95" align="center">
        <template slot="header">
          <div>
            <div>801-N95</div>
            <img src="@/assets/imgs/mask_801-N95.png" alt="" class="maskicon">
          </div>
        </template>
        <template slot-scope="{ row }">
          <span v-if="row.mask_801n95">{{ row.mask_801n95 }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="9132" align="center">
        <template slot="header">
          <div>
            <div>9132</div>
            <img src="@/assets/imgs/mask_9132.png" alt="" class="maskicon">
          </div>
        </template>
        <template slot-scope="{ row }">
          <span v-if="row.mask_9132">{{ row.mask_9132 }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="ENVIP3DF" align="center">
        <template slot="header">
          <div>
            <div>ENVIP3DF</div>
            <img src="@/assets/imgs/mask_ENVIP3DF.png" alt="" class="maskicon">
          </div>
        </template>
        <template slot-scope="{ row }">
          <span v-if="row.mask_envip3df">{{ row.mask_envip3df }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="N95(Folding type)" align="center">
        <template slot="header">
          <div>
            <div>N95(Folding type)</div>
            <img src="@/assets/imgs/mask_N95(foldingtype).png" alt="" class="maskicon">
          </div>
        </template>
        <template slot-scope="{ row }">
          <span v-if="row.mask_n95">{{ row.mask_n95 }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="N95-01" align="center">
        <template slot="header">
          <div>
            <div>N95-01</div>
            <img src="@/assets/imgs/mask_N95-01.png" alt="" class="maskicon">
          </div>
        </template>
        <template slot-scope="{ row }">
          <span v-if="row.mask_n9501">{{ row.mask_n9501 }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column label="推荐时间" align="center">
      <template slot-scope="{ row }">
        <span v-if="row.created_at">{{ row.created_at }}</span>
        <span v-else> - </span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
    name: 'flow-index',
    props: ['data', 'loading'],
    methods: { }
}
</script>