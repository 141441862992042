import { render, staticRenderFns } from "./info_index.vue?vue&type=template&id=fb452826&scoped=true&"
import script from "./info_index.vue?vue&type=script&lang=js&"
export * from "./info_index.vue?vue&type=script&lang=js&"
import style0 from "./info_index.vue?vue&type=style&index=0&id=fb452826&prod&lang=less&rel=stylesheet%2Fless&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb452826",
  null
  
)

export default component.exports