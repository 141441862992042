/** 财务流水列表 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.table-header {
  background-color: #f2f2f2;
}
.el-table-column-expand {
  width: 100px;
  color: #99a9bf;
  padding: 0 12px 0 0;
  line-height: 36px;
  font-weight: 700;
  display: inline-block;
}
</style>

<template>
  <el-table :data="data"
            border
            height="600"
            v-loading="loading">
    <!--
    <el-table-column v-for="(header,index) in table_headers"
                    class="table-header"
                    :key="index"
                    :prop="header.type"
                    :label="header.label"
                    :formatter="header.formatter"
                    :width="header.width">
    </el-table-column>
    -->
    <el-table-column type="expand" width="50px">
      <template slot-scope="props">
        <div>
          <div class="el-table-column-expand">流水单号</div>
          <div style="display: inline-block">{{ props.row.serial_no }}</div>
        </div>
        <div>
          <div class="el-table-column-expand">请求 ID</div>
          <div style="display: inline-block" v-if="props.row.request_id">{{ props.row.request_id }}</div>
          <div style="display: inline-block" v-else> - </div>
        </div>
        <div>
          <div class="el-table-column-expand">请求 IP</div>
          <div style="display: inline-block" v-if="props.row.request_ip">{{ props.row.request_ip }}</div>
          <div style="display: inline-block" v-else> - </div>
        </div>
        <div>
          <div class="el-table-column-expand">数据内容</div>
          <div style="display: inline-block" v-if="props.row.result_json">
            <div v-for="(item, index) in JSON.parse(props.row.result_json)"
              :key="index" style="border: 1px dashed #000; margin-top: 1px; margin-bottom: 1px;">
              <div style="margin: 10px;">
                <span v-if="item.code === 200 || item.code === '200'" style="color: white; background-color: green;">
                  数据状态：成功
                  <br />
                  素材uuid：{{ item.uuid }}
                </span>
                <span v-else style="color: white; background-color: red;">
                  数据状态：失败
                  <br />
                  素材uuid：{{ item.uuid }}
                  <br />
                  错误原因：{{ item.log }}
                </span>
              </div>
            </div>
          </div>
          <div style="display: inline-block" v-else> - </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="serial_no" label="流水单号" align="center" width="200px"></el-table-column>
    <el-table-column prop="request_type" label="API类型" align="center" width="120px">
      <template slot-scope="{ row }">
        <span v-if="row.request_type === 'tryon_g2d'"> 眼镜 2D 试戴 </span>
        <span v-else-if="row.request_type === 'tryon_g3d'"> 眼镜 3D 试戴 </span>
        <span v-else> {{ row.request_type }} </span>
      </template>
    </el-table-column>
    <el-table-column prop="request_id" label="请求 ID" align="center">
      <template slot-scope="{ row }">
        <span v-if="row.request_id"> {{ row.request_id }} </span>
        <span v-else> - </span>
      </template>
    </el-table-column>
    <el-table-column prop="request_ip" label="请求 IP" align="center">
      <template slot-scope="{ row }">
        <span v-if="row.request_ip"> {{ row.request_ip }} </span>
        <span v-else> - </span>
      </template>
    </el-table-column>
    <el-table-column prop="result_status" label="调用状态" align="center" width="140px">
      <template slot-scope="{ row }">
        <span v-if="row.result_status === 1 || row.result_status === '1'" style="color: green; font-weight: bold;"> 成功 </span>
        <span v-else-if="row.result_status === 2 || row.result_status === '2'" style="color: red; font-weight: bold;"> 失败 </span>
        <span v-else-if="row.result_status === 3 || row.result_status === '3'" style="color: blue; font-weight: bold;"> 部分成功 <br/> 部分失败 </span>
        <span v-else> - </span>
      </template>
    </el-table-column>
    <el-table-column prop="result_ok_count" label="调用成功" align="center" width="60px">
      <template slot-scope="{ row }">
        <span v-if="row.result_ok_count"> {{ row.result_ok_count }} </span>
        <span v-else> - </span>
      </template>
    </el-table-column>
    <el-table-column prop="result_fail_count" label="调用错误" align="center" width="60px">
      <template slot-scope="{ row }">
        <span v-if="row.result_fail_count"> {{ row.result_fail_count }} </span>
        <span v-else> - </span>
      </template>
    </el-table-column>
    <el-table-column prop="created_at" label="请求时间" align="center" width="160px"></el-table-column>
  </el-table>
</template>

<script>
export default {
    name: 'flow-index',
    props: ['data', 'loading'],
    data: function() {
        return {
            table_headers: [
                {
                    type: 'serial_no',
                    label: '流水单号',
                    width: '200'
                },
                {
                    type: 'request_type',
                    label: 'API类型',
                    width: '120'
                },
                {
                    type: 'request_id',
                    label: '请求 ID',
                    width: ''
                },
                {
                    type: 'request_ip',
                    label: '请求 IP',
                    width: ''
                },
                {
                    type: 'result_status',
                    label: '调用状态',
                    width: '60'
                },
                {
                    type: 'result_ok_count',
                    label: '调用成功',
                    width: '60'
                },
                {
                    type: 'result_fail_count',
                    label: '调用错误',
                    width: '60'
                },
                {
                    type: 'created_at',
                    label: '请求时间',
                    width: '160'
                }
            ]
        }
    },
    methods: {}
}
</script>