// =====ok.all
let local_token = sessionStorage.user_token

let balance = sessionStorage.balance

let user_info = JSON.parse(sessionStorage.getItem('user_info'))

export default {
  namespaced: true,
  state: {
    user_token: local_token,
    user_info: user_info,
    balance: balance
  },
  mutations: {
    setbalance (state, balance) {
      state.balance = balance
      sessionStorage.setItem('balance', balance)
    },
    setToken (state, token) {
      state.user_token = token
      sessionStorage.setItem('user_token', token)
    },
    setUserInfo (state, info) {
      state.user_info = info
      sessionStorage.setItem('user_info', JSON.stringify(info))
    },
    deleteToken (state) {
      state.user_token = null
      state.balance = 0
      sessionStorage.removeItem('user_token')
      sessionStorage.removeItem('balance')
    }
  },
  actions: {
    toSetToken ({ state, commit, rootState }, token) {
      commit('setToken', token)
    },
    toSetUserInfo ({ state, commit, rootState }, info) {
      commit('setUserInfo', info)
    }
  },
  getters: {
    getBalance (state) {
      return state.balance || 0.00
    },
    getToken (state) {
      return state.user_token
    },
    getUserInfo (state) {
      return state.user_info || {}
    }
  }
}