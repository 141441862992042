/*
 * 客户管理主视图
 * @Author: ljb
 * @Date: 2018-04-25 09:26:11
 * @Last Modified by: ljb
 * @Last Modified time: 2018-04-25 09:58:51
 */
<style lang="less" rel="stylesheet/less" type="text/less">
    .admin-customer-views{

    }
</style>
<template>
<div class="admin-customer-views">

    <el-tabs v-model="tab_name" type="card" >

        <el-tab-pane label="客户管理" name="客户管理">

            <search-form @search="confirmSearch"></search-form>

            <customer-index    :loading="loading"
                               :customers="api_users.data"
                               @edit="getCustomerDetail"
                               @delete="deleteCustomer"
                               @enabled="enabledCustomer"
                                @disabled="disabledCustomer"
                               v-loading="loading"
                               element-loading-text="请稍后"

                                >
            </customer-index>

            <br/>

            <el-pagination
                align="center"
                background
                @size-change="perPageChange"
                @current-change="pageChange"
                :current-page.sync="api_users.current_page"
                :page-size="query_info.per_page"
                layout="sizes, prev, pager, next"
                :total="api_users.total">
            </el-pagination>

        </el-tab-pane>
    </el-tabs>
    <!-- <el-dialog title="修改信息" :visible.sync="show_modal">
        <customer-edit :customerDetail="customer_detail"
                        @update="updateCustomer"
                        v-loading="loading"
                        element-loading-text="请稍后">
        </customer-edit>
        <div slot="footer"></div>
    </el-dialog> -->
</div>

</template>

<script>

import API_LIST from '../../../API_LIST';

import { mapGetters, mapMutations } from 'vuex'

import qs from 'qs';

/// import customerEdit from './edit';

import customerIndex from './index';

import searchForm from './search-form';

export default {
    name: 'admin-customer-views',
    components: {
        customerIndex,
        /// customerEdit,
        searchForm
    },
    data: function() {
        return {
            query_info: {
                page: 1,
                per_page: 20,
                name: ''
            },
            search_info: {
                page: 1,
                per_page: 20
            }, // 分页信息
            loading: false, // 表格更新时的loading
            show_modal: false, // 弹框的控制
            tab_name: '客户管理',
            api_users: {}, // 客户信息列表
            customer_detail: {} // 客户详情
        }
    },
    computed: {
        ...mapGetters('userToken', [
            'getToken'
        ])
    },
    mounted() {
        this.fetchCustomerList(this.query_info)
    },
    methods: {
         /**
         * 获取客户详情
         * @param  {object} detail {客户的详情}
         * @return {undefined}
         */
        getCustomerDetail(detail) {
            this.show_modal = true
            this.customer_detail = detail
        },
        /**
         * 获取客户列表
         * @param  {Object} param {搜索列表的参数}
         * @return {undefined}
         */
        fetchCustomerList(param) {
            this.loading = true
            let query_str = qs.stringify(param)
            this.$http.get(API_LIST.BYM_CUST + '?' + query_str)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.api_users = data.api_users
                })
                .catch((err) => {
                    console.log(err)
                    this.$message({
                        type: 'error',
                        message: '获取客户信息失败'
                    })
                })
                .then(() => { this.loading = false })
        },
        /**
         * 更新客户信息
         * @param  {string} id    {需要更新的客户的id}
         * @param  {Object} param {需要更新的客户对象}
         * @return {undefined}
         */
        updateCustomer(id, param) {
            this.loading = true
            this.$http.post(API_LIST.BYM_CUST + '/' + id, param)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.$message({
                        type: 'success',
                        message: '更新成功！'
                    })
                    this.show_modal = false
                    this.fetchCustomerList(this.search_info)
                })
                .catch((err) => {
                    console.log(err)
                    this.$message({
                        type: 'error',
                        message: '更新失败！'
                    })
                })
                .then(() => { this.loading = false })
        },
        /**
         * 删除客户
         * @param  {string} id {需要删除的客户的id}
         * @return {undefined}
         */
        deleteCustomer(id) {
            this.$confirm('此操作将永久删除该条记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(() => {
                const param = {
                    _method: 'DELETE'
                }
                this.loading = true
                this.$http.post(API_LIST.BYM_CUST + '/' + id, param)
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.$message({
                            type: 'success',
                            message: '删除成功！'
                        })
                        this.fetchCustomerList(this.search_info)
                    })
                    .catch((err) => {
                         console.log(err)
                         this.$message({
                            type: 'error',
                            message: '删除失败！'
                        })
                    })
                    .then(() => { this.loading = false })
            })
            .catch(() => { })
        },
        /**
         * 停用客户
         * @param  {Object} row {客户对象}
         * @return {undefined}
         */
        disabledCustomer(row) {
            this.$confirm('确定要停用该客户吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(() => {
                this.loading = true
                this.$http.patch(API_LIST.BYM_PATCH_CUSTOMER + '/' + row.id)
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.$message({
                            type: 'success',
                            message: '停用成功！'
                        })
                        this.fetchCustomerList(this.search_info)
                    })
                    .catch((err) => {
                         console.log(err)
                         this.$message({
                            type: 'error',
                            message: '停用失败！'
                        })
                    })
                    .then(() => { this.loading = false })
            })
            .catch(() => { })
        },
        /**
         * 启用客户
         * @param  {Object} row {客户对象}
         * @return {undefined}
         */
        enabledCustomer(row) {
            this.$confirm('确定要启用该客户吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(() => {
                this.loading = true
                this.$http.patch(API_LIST.BYM_PATCH_CUSTOMER + '/' + row.id)
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.$message({
                            type: 'success',
                            message: '启用成功！'
                        })
                        this.fetchCustomerList(this.search_info)
                    })
                    .catch((err) => {
                         console.log(err)
                         this.$message({
                            type: 'error',
                            message: '启用失败！'
                        })
                    })
                    .then(() => { this.loading = false })
            })
            .catch(() => { })
        },
         /**
         * 页码改变
         * @param  {type} pageNumber {当前页码}
         * @return {undefined}
         */
        pageChange(pageNumber) {
            this.search_info.page = pageNumber
            this.search_info.per_page = this.query_info.per_page
            this.fetchCustomerList(this.search_info)
        },
        /**
         * 每页显示数改变
         * @param  {type} per_page {当前每页显示数}
         * @return {undefined}
         */
        perPageChange(per_page) {
            this.search_info.per_page = per_page
            this.query_info.per_page = per_page
            this.fetchCustomerList(this.search_info)
        },
        /**
         * 确认搜索
         * @param  {Object} param {所需查询的对象}
         * @return {undefined}
         */
        confirmSearch(param) {
            this.query_info.name = param.name || ''
            this.search_info = JSON.parse(JSON.stringify(this.query_info))
            this.fetchCustomerList(this.query_info)
        }
    }
}
</script>