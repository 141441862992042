var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.data,"border":"","height":"600"}},[_c('el-table-column',{attrs:{"type":"expand","width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.user)?_c('div',[_c('div',{staticClass:"el-table-column-expand"},[_vm._v("微信openid")]),(props.row.user && props.row.user.openid)?_c('div',{staticStyle:{"display":"inline-block"}},[_c('span',[_vm._v(_vm._s(props.row.user.openid))])]):_c('div',{staticStyle:{"display":"inline-block"}},[_c('span',[_vm._v(" - ")])])]):_vm._e(),_c('div',[_c('div',{staticClass:"el-table-column-expand"},[_vm._v("微信头像")]),(props.row.user && props.row.user.userInfo && props.row.user.userInfo.avatar)?_c('div',{staticStyle:{"display":"inline-block"}},[_c('img',{staticClass:"wxavatar",attrs:{"src":props.row.user.userInfo.avatar,"alt":"","mode":"widthFix"}})]):_c('div',{staticStyle:{"display":"inline-block"}},[_c('span',[_vm._v(" - ")])])]),_c('div',[_c('div',{staticClass:"el-table-column-expand"},[_vm._v("微信昵称")]),(props.row.user && props.row.user.userInfo && props.row.user.userInfo.nickname)?_c('div',{staticStyle:{"display":"inline-block"}},[_c('span',[_vm._v(_vm._s(props.row.user.userInfo.nickname))])]):_c('div',{staticStyle:{"display":"inline-block"}},[_c('span',[_vm._v(" - ")])])])]}}])}),_c('el-table-column',{attrs:{"label":"人脸图像","align":"center","span":"2"}},[_c('el-table-column',{attrs:{"label":"原始图","align":"center","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.original_image)?_c('span',[_c('img',{staticClass:"maskimg mask-original",attrs:{"src":row.original_image,"alt":"","mode":"widthFix"}})]):_c('span',[_vm._v(" - ")])]}}])}),_c('el-table-column',{attrs:{"label":"结果图","align":"center","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.processed_image)?_c('span',[_c('img',{staticClass:"maskimg mask-processed",attrs:{"src":row.processed_image,"alt":"","mode":"widthFix"}})]):_c('span',[_vm._v(" - ")])]}}])})],1),_c('el-table-column',{attrs:{"label":"脸型信息","align":"center","span":"8"}},[_c('el-table-column',{attrs:{"label":"脸型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.face_type_value)?_c('span',[_vm._v(_vm._s(row.face_type_value))]):_c('span',[_vm._v(" - ")])]}}])}),_c('el-table-column',{attrs:{"label":"下巴角度","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.jaw_angle)?_c('span',[_vm._v(_vm._s(row.jaw_angle)+" "+_vm._s(row.jaw_angle_unit))]):_c('span',[_vm._v(" - ")])]}}])}),_c('el-table-column',{attrs:{"label":"下巴宽度","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.jaw_width)?_c('span',[_vm._v(_vm._s(row.jaw_width)+" "+_vm._s(row.jaw_width_unit))]):_c('span',[_vm._v(" - ")])]}}])}),_c('el-table-column',{attrs:{"label":"下颌角度数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.mandible_angle)?_c('span',[_vm._v(_vm._s(row.mandible_angle)+" "+_vm._s(row.mandible_angle_unit))]):_c('span',[_vm._v(" - ")])]}}])}),_c('el-table-column',{attrs:{"label":"下颌角宽度","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.mandible_length)?_c('span',[_vm._v(_vm._s(row.mandible_length)+" "+_vm._s(row.mandible_length_unit))]):_c('span',[_vm._v(" - ")])]}}])}),_c('el-table-column',{attrs:{"label":"鼻梁宽度","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.nose_bridge_width)?_c('span',[_vm._v(_vm._s(row.nose_bridge_width)+" "+_vm._s(row.nose_bridge_width_unit))]):_c('span',[_vm._v(" - ")])]}}])}),_c('el-table-column',{attrs:{"label":"鼻翼宽度","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.nose_width)?_c('span',[_vm._v(_vm._s(row.nose_width)+" "+_vm._s(row.nose_width_unit))]):_c('span',[_vm._v(" - ")])]}}])}),_c('el-table-column',{attrs:{"label":"颧骨宽度","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.zygoma_length)?_c('span',[_vm._v(_vm._s(row.zygoma_length)+" "+_vm._s(row.zygoma_length_unit))]):_c('span',[_vm._v(" - ")])]}}])})],1),_c('el-table-column',{attrs:{"label":"口罩匹配度","align":"center","span":"5"}},[_c('el-table-column',{attrs:{"label":"801-N95","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.mask_801n95)?_c('span',[_vm._v(_vm._s(row.mask_801n95))]):_c('span',[_vm._v(" - ")])]}}])},[_c('template',{slot:"header"},[_c('div',[_c('div',[_vm._v("801-N95")]),_c('img',{staticClass:"maskicon",attrs:{"src":require("@/assets/imgs/mask_801-N95.png"),"alt":""}})])])],2),_c('el-table-column',{attrs:{"label":"9132","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.mask_9132)?_c('span',[_vm._v(_vm._s(row.mask_9132))]):_c('span',[_vm._v(" - ")])]}}])},[_c('template',{slot:"header"},[_c('div',[_c('div',[_vm._v("9132")]),_c('img',{staticClass:"maskicon",attrs:{"src":require("@/assets/imgs/mask_9132.png"),"alt":""}})])])],2),_c('el-table-column',{attrs:{"label":"ENVIP3DF","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.mask_envip3df)?_c('span',[_vm._v(_vm._s(row.mask_envip3df))]):_c('span',[_vm._v(" - ")])]}}])},[_c('template',{slot:"header"},[_c('div',[_c('div',[_vm._v("ENVIP3DF")]),_c('img',{staticClass:"maskicon",attrs:{"src":require("@/assets/imgs/mask_ENVIP3DF.png"),"alt":""}})])])],2),_c('el-table-column',{attrs:{"label":"N95(Folding type)","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.mask_n95)?_c('span',[_vm._v(_vm._s(row.mask_n95))]):_c('span',[_vm._v(" - ")])]}}])},[_c('template',{slot:"header"},[_c('div',[_c('div',[_vm._v("N95(Folding type)")]),_c('img',{staticClass:"maskicon",attrs:{"src":require("@/assets/imgs/mask_N95(foldingtype).png"),"alt":""}})])])],2),_c('el-table-column',{attrs:{"label":"N95-01","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.mask_n9501)?_c('span',[_vm._v(_vm._s(row.mask_n9501))]):_c('span',[_vm._v(" - ")])]}}])},[_c('template',{slot:"header"},[_c('div',[_c('div',[_vm._v("N95-01")]),_c('img',{staticClass:"maskicon",attrs:{"src":require("@/assets/imgs/mask_N95-01.png"),"alt":""}})])])],2)],1),_c('el-table-column',{attrs:{"label":"推荐时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.created_at)?_c('span',[_vm._v(_vm._s(row.created_at))]):_c('span',[_vm._v(" - ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }