import axios from 'axios';
import env from '../config/env';
import Qs from 'qs';

let util = {

};
util.title = function(title) {
    title = title ? title + ' - 管理后台' : '管理后台';
    window.document.title = title;
};

const ajaxUrl = env === 'development' ? 'http://127.0.0.1:9898' : env === 'production' ? 'https://www.url.com' : 'https://debug.url.com';

util.ajax = axios.create({
    baseURL: ajaxUrl,
    timeout: 30000,
    transformRequest: [function (data) {
        // 这里可以在发送请求之前对请求数据做处理，比如form-data格式化等，这里可以使用开头引入的Qs（这个模块在安装axios的时候就已经安装了，不需要另外安装）
    　　data = Qs.stringify({});
        return data;
    }]
});

util.isNull = function (val) {
    if (typeof val === 'object' && !val) {
        return true
    } else {
        return false
    }
}

export default util;