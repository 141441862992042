<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>
  <el-form :inline="true" :model="form_data" class="demo-form-inline">
    <el-form-item label="数据类型">
      <el-select v-model="form_data.balance_type" clearable placeholder="请选择">
        <el-option v-for="balance_type in balance_types"
                   :key="balance_type.value"
                   :label="balance_type.label"
                   :value="balance_type.value">
        </el-option>
      </el-select>
    </el-form-item>
    <span style="margin-left: 10px; margin-right: 10px;"></span>
    <el-form-item label="查询日期">
      <el-date-picker v-model="form_data.time_range"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions">
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="$emit('search',form_data)">查询</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'finance-search-form',
  data: function() {
    return {
      // 余额类型
      balance_types: [
        {
          label: '全部',
          value: 1
        },
        {
          label: '眼镜2D试戴',
          value: 2
        },
        {
          label: '眼镜3D试戴',
          value: 3
        }
      ],
      // 表单数据
      form_data: {
        balance_type: '',
        time_range: []
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      } // element-ui带快捷选项的时间picker选项
    }
  },
  mounted () {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    this.form_data.time_range = [this.dataToString(start), this.dataToString(end)];
  },
  methods: {
    dataToString (date) {
      let year = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      if (month.length === 1) {
        month = '0' + month;
      }
      if (day.length === 1) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    }
  }
}
</script>