/*
 * 充值订单列表
 * @Author: ljb
 * @Date: 2018-04-26 10:36:02
 * @Last Modified by: ljb
 * @Last Modified time: 2018-04-27 10:24:48
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>
  <div> </div>
</template>

<script>
export default {
    name: 'recharge-index',
    data () {
      return { }
    },
    methods: { }
}
</script>