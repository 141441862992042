// =====ok.all
export default {
  namespaced: true,
  state: {
    spinshow: false,
    spin_text: ''
  },
  mutations: {
    showSpin (state, text) {
      if (!state.spinshow) {
        state.spinshow = true
      }
      state.spin_text = text
    },
    closeSpin (state) {
      state.spinshow = false
      state.spin_text = ''
    }
  },
  actions: { },
  getters: {
    getSpin (state) {
      return state.spinshow
    },
    getSpinText (state) {
      return state.spin_text
    }
  }
}