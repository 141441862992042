/** 3D素材修改 */
<style lang="less" rel="stylesheet/less" type="text/less">

</style>

<template>
  <div class="customer-material-3D-edit">
    <el-form :model="formValidate" :rules="ruleValidate" ref="formValidate" label-width="100px" class="demo-ruleForm">
      <el-form-item label="素材名称" prop="name">
        <el-col :span="15">
          <el-input v-model="formValidate.name" placeholder="请输入素材名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="素材编码" prop="uuid">
        <el-col :span="15">
          <el-input v-model="formValidate.uuid" placeholder="请输入素材编码"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="素材上传" prop="model">
        <!-- :on-remove="file=>fileChange(file,'model','file_info')" -->
        <el-upload class="upload-demo"
                :before-upload="file=>fileChange(file,'model','file_info')"
                :limit="1"
                action="//fake_upload_url/">
          <el-button size="small" type="primary">点击上传</el-button>
          <div class="license-tip" slot="tip" v-if="file_info.name">{{ file_info.name }}</div>
          <div class="license-tip" slot="tip" v-else>{{ json_name }}</div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="updateMaterial('formValidate')">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'admin-material-3D-edit',
  props: ['materialDetail', 'showModal'],
  data() {
    return {
      formValidate: {
        name: '',
        uuid: '',
        model: ''
      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入素材名称', trigger: 'blur change' }
        ],
        uuid: [
          { required: true, message: '请输入素材编码', trigger: 'blur change' }
        ],
        model: [
          { required: true, message: '请上传3D素材模型', trigger: 'blur,change' }
        ]
      },
       // 文件信息
      file_info: {
        name: ''
      },
      json_name: ''
    }
  },
  watch: {
    materialDetail: function() {
      if (this.materialDetail) {
        this.formValidate.name = this.materialDetail.name;
        this.formValidate.uuid = this.materialDetail.uuid;
        this.formValidate.model = this.materialDetail.model;
      }
    },
    'formValidate.model': function() {
      if (this.formValidate.model) {
        let new_str = String(this.formValidate.model);
        let index = new_str.lastIndexOf('/');
        new_str = new_str.substring(index + 1, new_str.length);
        this.json_name = new_str;
      }
    },
    showModal: function() {
      if (!this.showModal) {
        this.resetMaterialInfo();
      }
    }
  },
  methods: {
    /** file类型input改变 */
    fileChange(file, param, info) {
      this[info].name = file.name;
      this.formValidate[param] = file;
      return false
    },
    /** 重置素材信息 */
    resetMaterialInfo () {
      if (this.file_info.name) {
        this.json_name = this.file_info.name;
      }
      this.file_info.name = '';
    },
    /** 更新素材信息 */
    updateMaterial(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.resetMaterialInfo()
          this.$emit('update', this.materialDetail.id, this.formDataCreate())
        } else {
          this.$message({
            type: 'error',
            message: '不满足提交条件!'
          })
        }
      })
    },
    /** 手动拼接formdata数据 */
    formDataCreate () {
      const new_object = {
        _method: 'PUT',
        name: this.formValidate.name,
        uuid: this.formValidate.uuid,
        model: this.formValidate.model
      }
      let form_data = new FormData()
      Object.keys(new_object).forEach((key) => {
        form_data.append(key, new_object[key])
      })
      return form_data
    }
  }
}
</script>