const APP_DEBUG = process.env.DEV;

const USER_TOKEN = 'user_token';

const TOKEN_ERROR_CODE = 3010; // invalid token的code值

/// const HOST_NAME = 'https://tryon.xiaojingxiuxiu.com'; // location.origin;
/// const HOST_NAME = 'http://localhost:8080'; // location.origin;
const HOST_NAME = '';

const API_PREFIX = '/api/open-try-on/admin';

const BYM_API = '/api/open-try-on/bym-admin';

const BASE_SERVICE = '/api/base-service';

// https://tryon.xiaojingxiuxiu.com/api/open-try-on/admin/login
const ADMIN_LOGIN = HOST_NAME + API_PREFIX + '/login'; // 登录

const ADMIN_REGISTER = HOST_NAME + API_PREFIX + '/api-user'; // 注册

const ADMIN_ACTIVATE = HOST_NAME + API_PREFIX + '/api-user-active'; // 激活

const FORGET_PASSWORD = HOST_NAME + API_PREFIX + '/api-user/forget-password'; // 激活

const RESET_PASSWORD = HOST_NAME + API_PREFIX + '/api-user/reset-password'; // 重置密码

const ADMIN_BALANCE = HOST_NAME + API_PREFIX + '/api-user-info'; // 用户的余额信息

const ADMIN_RECHARGE = HOST_NAME + API_PREFIX + '/recharge'; // 用户充值

const ADMIN_IS_RECHARGE = HOST_NAME + API_PREFIX + '/is-pay'; // 充值是否完成

const ADMIN_FINANCE = HOST_NAME + API_PREFIX + '/finance'; // 财务流水

const ADMIN_SERVICE = HOST_NAME + API_PREFIX + '/service'; // 服务订单api接口（对应购买服务）

const ADMIN_2D_MATERIAL = HOST_NAME + API_PREFIX + '/glass-2d-assets'; // 2D素材

const ADMIN_3D_MATERIAL = HOST_NAME + API_PREFIX + '/glass-3d-assets'; // 3D素材

const ADMIN_UPDATE_API_USER = HOST_NAME + API_PREFIX + '/update-api-user'; // 保存用户信息

const ADMIN_UPDATE_APP_KEY = HOST_NAME + API_PREFIX + '/update-app-key'; // 保存密钥信息

const BYM_LOGIN = HOST_NAME + BYM_API + '/login'; // 便雅悯超管登录

const BYM_CUST = HOST_NAME + BYM_API + '/customer'; // 便雅悯客户管理

const BYM_RECHARGE = HOST_NAME + BYM_API + '/recharge'; // 充值订单

const BYM_FLOW = HOST_NAME + BYM_API + '/cash-flow'; // 资金流水

const BYM_SERVICE = HOST_NAME + BYM_API + '/service'; // 服务订单

const BYM_USER = HOST_NAME + BYM_API + '/bym-user'; // 个人信息

const BYM_SERVICE_PRICE = HOST_NAME + BYM_API + '/bym-service'; // 服务价格

const BYM_PATCH_CUSTOMER = HOST_NAME + BYM_API + '/patch-customer'; // 启用和禁用客户

export default {

    APP_DEBUG,

    USER_TOKEN,

    TOKEN_ERROR_CODE,

    ADMIN_LOGIN,

    ADMIN_REGISTER,

    ADMIN_ACTIVATE,

    FORGET_PASSWORD,

    RESET_PASSWORD,

    ADMIN_BALANCE,

    ADMIN_RECHARGE,

    ADMIN_IS_RECHARGE,

    ADMIN_FINANCE,

    ADMIN_SERVICE,

    ADMIN_2D_MATERIAL,

    ADMIN_3D_MATERIAL,

    ADMIN_UPDATE_API_USER,

    ADMIN_UPDATE_APP_KEY,

    BYM_LOGIN,

    BYM_CUST,

    BYM_RECHARGE,

    BYM_FLOW,

    BYM_SERVICE,

    BYM_USER,

    BYM_SERVICE_PRICE,

    BYM_PATCH_CUSTOMER

};
