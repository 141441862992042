/*
 * 充值订单创建
 * Author: ljb
 * Date: 2018-04-26 10:18:23
 * Last Modified by: ljb
 * Last Modified time: 2018-04-26 10:52:01
 */
<style scoped>

</style>

<template>
<el-form ref="recharge_form" status-icon :model="form_data" :rules="ruleInline" label-width="80px">

    <el-form-item label="选择金额" prop="amount">
        <el-radio-group v-model="form_data.amount">
            <el-radio v-for="(amount,index) in can_select_amounts" :key="index" :label="amount">{{amount}}</el-radio>
        </el-radio-group>
    </el-form-item>
    <el-form-item label="支付方式" prop="pay_type_id">
        <el-radio-group v-model="form_data.pay_type_id">
            <el-radio v-for="(pay_type,index) in payTypes" :key="index" :label="pay_type.id">{{pay_type.name}}</el-radio>
        </el-radio-group>
    </el-form-item>
    <el-form-item>
        <el-button type="primary" @click="handleSubmit('recharge_form')" round>充值</el-button>
    </el-form-item>

</el-form>
</template>

<script>
export default {
    name: 'recharge-create',
    props: ['payTypes'],
    data () {
        return {
            can_select_amounts: [1000, 800, 500, 300, 100, 50], // 可选择的金额条目
            form_data: {
                amount: '',
                pay_type_id: ''
            }, // 充值所需表单
            ruleInline: {
                amount: [
                    { required: true, message: '请选择支付的金额', trigger: 'change' }
                ],
                pay_type_id: [
                    { required: true, message: '请选择支付方式', trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        /**
         * 提交表单的方法
         * @param  {string} name {form表单绑定的id}
         * @return {undefined}
         */
        handleSubmit (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$emit('post', this.form_data)
                } else {
                    this.$message({
                        type: 'error',
                        message: '不满足提交条件！'
                    })
                }
            })
        }
    }
}
</script>