<style lang="less" rel="stylesheet/less">
.app {
  /*不换行*/
  .nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /*不换行 END*/
  /*上传图片的信息提示*/
  .info-tips {
    color: #ccc;
  }
  /*上传图片的信息提示 END*/
}
/*图片上传本地预览的样式 STRAT*/
.preview-image {
  width: 250px;
  display: block;
  margin-top: 15px;
}
/*图片上传本地预览的样式 END*/
</style>

<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return { }
  },
  mounted() { },
  beforeDestroy() { },
  methods: { }
}
</script>