/*
 * 服务价格管理主视图
 * Author: LuoQianJun
 * Date: 2018-05-02 09:26:11
 * Last Modified by: LuoQianJun
 * Last Modified time: 2018-05-02 15:06:40
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
<div class="admin-bym-service-price-views">

    <el-tabs v-model="tab_name" type="card" @tab-click="handleClick"  v-loading="loading"
                               element-loading-text="请稍后">

        <!-- <el-tab-pane label="新增" name="新增">
            <service-create ref="create"
                            :services="services"
                            @post="postData" >
            </service-create>

        </el-tab-pane> -->

        <el-tab-pane label="服务价格" name="服务价格">
            <service-index  :loading="loading"
                            :billing-types="billing_types.data"
                            :services="services"
                            @delete="deleteServicePrice"
                            @edit="getServiceDetail">
            </service-index>

            <br/>

            <el-pagination
                align="center"
                background
                @size-change="perPageChange"
                @current-change="pageChange"
                :current-page.sync="billing_types.current_page"
                :page-size="query_info.per_page"
                layout="sizes, prev, pager, next"
                :total="billing_types.total">
            </el-pagination>
        </el-tab-pane>

    </el-tabs>

    <el-dialog title="修改信息" :visible.sync="show_modal" @close="closeDialog">
        <service-edit :service-detail="service_detail"
                        :services="services"
                        @update="updateServicePrice"
                        v-loading="loading"
                        element-loading-text="请稍后">

        </service-edit>
    </el-dialog>

</div>

</template>

<script>

import API_LIST from '../../../API_LIST';

// import { mapGetters , mapMutations } from "vuex";

import qs from 'qs';

/// import serviceCreate from './create';

import serviceIndex from './index';

import serviceEdit from './edit';

export default {

    name: 'admin-bym-service-price-views',

    components: {
        /// serviceCreate,
        serviceIndex,
        serviceEdit
    },

    data: function() {
        return {
            query_info: {
                page: 1,
                per_page: 20
            },
            search_info: {
                page: 1,
                per_page: 20
            }, // 分页信息
            loading: false,
            show_modal: false, // 弹框的控制
            tab_name: '服务价格',
            service_detail: {}, // 详情
            services: [], // 类型
            billing_types: {} // 服务价格列表
        }
    },
    mounted() {
        this.fetchServicePriceType()
        this.fetchServicePriceList(this.query_info)
    },
    methods: {

        /**
         * 切换tab
         * @param  {string} tab_name {tab的name}
         * @return {undefined}
         */
        handleClick(tab) {
            if (tab.name === '服务价格') {
                this.fetchServicePriceList(this.query_info)
            } else {
                this.billing_types = []
            }
        },

        /**
         * 关闭弹框后的回调
         * @return {undefined}
         */
        closeDialog() {
            this.service_detail = null
        },

         /**
         * 获取服务价格详情
         * @param  {object} detail {服务价格的详情}
         * @return {undefined}
         */
        getServiceDetail(detail) {
            this.show_modal = true
            this.$nextTick(() => { this.service_detail = detail })
        },

        /**
         * 新增服务价格的post数据
         * @return {undefined}
         */
        postData(param) {
            this.loading = true
            this.$http.post(API_LIST.BYM_SERVICE_PRICE, param)
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.$message({
                            type: 'success',
                            message: '新增成功'
                        })
                        this.$refs.create.handleReset('formValidate')
                    })
                    .catch((err) => {
                      console.log(err)
                       this.$message({
                            type: 'error',
                            message: '新增失败'
                        })
                    })
                    .then(() => { this.loading = false })
        },

        /**
         * 获取服务价格列表
         * @param  {Object} param {搜索列表的参数}
         * @return {undefined}
         */
        fetchServicePriceList(param) {
            this.loading = true
            let query_str = qs.stringify(param)
            this.$http.get(API_LIST.BYM_SERVICE_PRICE + '?' + query_str)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.billing_types = data.billing_types
                })
                .catch((err) => {
                  console.log(err)
                    this.$message({
                        type: 'error',
                        message: '获取信息失败'
                    })
                })
                .then(() => { this.loading = false })
        },

        /**
         * 获取服务价格类型
         * @return {undefined}
         */
        fetchServicePriceType() {
            this.loading = true
            this.$http.get(API_LIST.BYM_SERVICE_PRICE + '/create')
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.services = data.services
                })
                .catch((err) => {
                    console.log(err)
                    this.$message({
                        type: 'error',
                        message: '获取类型失败'
                    })
                })
                .then(() => { this.loading = false })
        },

        /**
         * 更新服务价格
         * @param  {string} id    {需要更新的服务价格的id}
         * @param  {Object} param {需要更新的服务价格对象}
         * @return {undefined}
         */
        updateServicePrice(id, param) {
            this.loading = true
            this.$http.post(API_LIST.BYM_SERVICE_PRICE + '/' + id, param)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.$message({
                        type: 'success',
                        message: '更新成功！'
                    })
                    this.show_modal = false
                    this.fetchServicePriceList(this.search_info)
                })
                .catch((err) => {
                    console.log(err)
                    this.$message({
                        type: 'error',
                        message: '更新失败！'
                    })
                })
                .then(() => { this.loading = false })
        },

        /**
         * 删除服务价格
         * @param  {string} id {需要删除的服务的id}
         * @return {undefined}
         */
        deleteServicePrice(id) {
            this.$confirm('此操作将永久删除该条记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(() => {
                const param = {
                    _method: 'DELETE'
                }
                this.loading = true;
                this.$http.post(API_LIST.BYM_SERVICE_PRICE + '/' + id, param)
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.$message({
                            type: 'success',
                            message: '删除成功！'
                        })
                        this.fetchServicePriceList(this.search_info)
                    })
                    .catch((err) => {
                         console.log(err)
                         this.$message({
                            type: 'error',
                            message: '删除失败！'
                        })
                    })
                    .then(() => { this.loading = false })
            })
            .catch(() => { })
        },

        /**
         * 页码改变
         * @param  {type} pageNumber {当前页码}
         * @return {undefined}
         */
        pageChange(pageNumber) {
            this.search_info.page = pageNumber
            this.search_info.per_page = this.query_info.per_page
            this.fetchServicePriceList(this.search_info)
        },

        /**
         * 每页显示数改变
         * @param  {type} per_page {当前每页显示数}
         * @return {undefined}
         */
        perPageChange(per_page) {
            this.search_info.per_page = per_page
            this.query_info.per_page = per_page
            this.fetchServicePriceList(this.search_info)
        }
    }
}
</script>