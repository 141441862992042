// 设置cookies
export function setCookie(name, value, time) {
    // var date = new Date();
    let date = new Date();
    date.setTime(date.getTime() + time);
    document.cookie = name + '=' + value + ';expires=' + date.toGMTString();
};

// 获取cookies
export function getCookie(name) {
    // var reg = RegExp(name + '=([^;]+)');
    // var arr = document.cookie.match(reg);
    let reg = RegExp(name + '=([^;]+)');
    let arr = document.cookie.match(reg);
    if (arr) {
        return arr[1];
    } else {
        return '';
    }
};

// 删除cookies
export function delCookie(name) {
    setCookie(name, null, -1);
};