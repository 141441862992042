/** 2D素材创建 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.customer-material-2D-create {
  margin: 5px 0 0 20px;
}
.btn {
  position: absolute;
  left: 0;
}
</style>

<template>
  <div class="customer-material-2D-create">
    <el-form :model="formValidate" :rules="ruleValidate" ref="formValidate" label-width="100px" class="demo-ruleForm">
      <el-form-item label="素材名称" prop="name">
        <el-col :span="10">
          <el-input v-model="formValidate.name" placeholder="请输入素材名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="素材编码" prop="uuid">
        <el-col :span="10">
          <el-input v-model="formValidate.uuid" placeholder="请输入素材编码"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="镜框图片" prop="frame_img">
        <el-upload class="upload-demo"
                :before-upload="file=>fileChange(file,'frame_img','file_info')"
                :limit="1"
                accept="image/jpg, image/jpeg, image/png"
                action="//fake_upload_url/">
          <el-button size="small" type="primary">点击上传</el-button>
          <!-- <div class="license-tip" slot="tip" v-if="file_info.frame_name">{{ file_info.frame_name }}</div> -->
        </el-upload>
        <img class="preview-image" ref="frame" alt="" v-show="file_info.frame_name" >
      </el-form-item>
      <!--
      <el-form-item label="镜腿图片" prop="temple_img">
          <el-upload class="upload-demo"
                  :before-upload="file=>fileChange(file,'temple_img','file_info')"
                  :limit="1"
                  accept="image/jpg, image/jpeg, image/png"
                  action="//fake_upload_url/">
            <el-button size="small" type="primary">点击上传</el-button>
            //注释此行 <div class="license-tip" slot="tip" v-if="file_info.temple_name">{{ file_info.temple_name }}</div>
          </el-upload>
          <img class="preview-image" src="" ref="temple" alt="" v-show="file_info.temple_name" >
      </el-form-item>
      -->
      <el-form-item>
        <el-button type="primary" @click="handleSubmit('formValidate')">新增</el-button>
        <el-button @click="handleReset('formValidate')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validateImage } from '../../../../validate' // 引入校验文件
import { getUploadImage } from '../../../../utils/utils'
export default {
  name: 'admin-material-2D-create',
  data() {
    return {
      formValidate: {
        name: '',
        uuid: '',
        frame_img: ''
        // temple_img: ''
      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入素材名称', trigger: 'blur change' }
        ],
        uuid: [
          { required: true, message: '请输入素材编码', trigger: 'blur change' }
        ],
        frame_img: [
          { required: true, validator: validateImage, trigger: 'blur,change' }
        ]
        /**
        temple_img: [
          { required: true, validator: validateImage, trigger: 'blur,change' }
        ],
        */
      },
      file_info: {
        frame_name: '',
        temple_name: ''
      }
    }
  },
  methods: {
    /** file类型input改变 */
    fileChange(file, param, info) {
      switch (param) {
        case 'frame_img':
          this[info].frame_name = file.name
          getUploadImage(file, this.$refs.frame)
          break
        case 'temple_img':
          this[info].temple_name = file.name
          getUploadImage(file, this.$refs.temple)
          break
      }
      this.formValidate[param] = file
      return false
    },
    /** 提交表单 */
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.dataPost()
        } else {
          this.$message({
            type: 'error',
            message: '不满足提交条件!'
          })
        }
      })
    },
    /** 重置表单 */
    handleReset (name) {
      this.$refs[name].resetFields()
      this.file_info.frame_name = ''
      this.file_info.temple_name = ''
    },
    /** post数据 */
    dataPost() {
      const form_data = this.formDataCreate()
      this.$emit('post', form_data)
    },
    /** 手动拼接formdata数据 */
    formDataCreate() {
      const new_object = {
        name: this.formValidate.name,
        uuid: this.formValidate.uuid,
        frame_img: this.formValidate.frame_img
        // temple_img: this.formValidate.temple_img
      }
      let form_data = new FormData()
      Object.keys(new_object).forEach((key) => {
        form_data.append(key, new_object[key])
      })
      return form_data
    }
  }
}
</script>