<template>
  <div class="v-customer-home-index layout-content">
    <el-row :gutter="20">
      <h3 class="left-border">总量概览</h3>
      <br />
      <el-col :span="5" class="home-col">
        <el-container class="balance-box">
          <el-header height="50px" class="box-header">
            用户总数量
          </el-header>
          <el-main class="box-body" v-loading="balance_loading">
            <p class="balance-cash" v-if="dataAccountInfo.user_count !== undefined && dataAccountInfo.user_count !== null">{{ dataAccountInfo.user_count }} </p>
            <p class="balance-cash" v-else> 0 </p>
          </el-main>
        </el-container>
      </el-col>
      <el-col :span="5" class="home-col">
        <el-container class="balance-box">
          <el-header height="50px" class="box-header">
            推荐总数量
          </el-header>
          <el-main class="box-body" v-loading="balance_loading">
            <p class="balance-cash" v-if="dataAccountInfo.analysis_count !== undefined && dataAccountInfo.analysis_count !== null">{{ dataAccountInfo.analysis_count }} </p>
            <p class="balance-cash" v-else> 0 </p>
          </el-main>
        </el-container>
      </el-col>
    </el-row>
    <br />
    <br />
    <el-row :gutter="20">
      <h3 class="left-border">活跃概览</h3>
      <div class="UsageInfo-Content" v-loading="loading_usageInfo">
        <el-tabs v-model="activeName" @tab-click="onCallUsageInfoTabClick">
            <el-tab-pane name="h24" label="近24小时">
              <el-table :data="tableDataByUsageInfo" style="width: 100%;">
                <!-- prop:数组字段 label表头字段 align 表格中字体居中 -->
                <el-table-column prop="user_login_count" label="登录总用户" align="center"  />
                <el-table-column prop="analysis_user_count" label="推荐总用户"  align="center"/>
                <el-table-column prop="analysis_active_count" label="推荐总活跃" align="center"/>
                <el-table-column prop="last_at" label="最近推荐时间" align="center">
                  <template slot-scope="{ row }">
                    <span v-if="row.last_at"> {{ row.last_at }} </span>
                    <span v-else> - </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane name="d7" label="近7天">
              <el-table :data="tableDataByUsageInfo" style="width: 100%;">
                <!-- prop:数组字段 label表头字段 align 表格中字体居中 -->
                <el-table-column prop="user_login_count" label="登录总用户" align="center"  />
                <el-table-column prop="analysis_user_count" label="推荐总用户"  align="center"/>
                <el-table-column prop="analysis_active_count" label="推荐总活跃" align="center"/>
                <el-table-column prop="last_at" label="最近推荐时间" align="center">
                  <template slot-scope="{ row }">
                    <span v-if="row.last_at"> {{ row.last_at }} </span>
                    <span v-else> - </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane name="d30" label="近30天">
              <el-table :data="tableDataByUsageInfo" style="width: 100%;">
                <!-- prop:数组字段 label表头字段 align 表格中字体居中 -->
                <el-table-column prop="user_login_count" label="登录总用户" align="center"  />
                <el-table-column prop="analysis_user_count" label="推荐总用户"  align="center"/>
                <el-table-column prop="analysis_active_count" label="推荐总活跃" align="center"/>
                <el-table-column prop="last_at" label="最近推荐时间" align="center">
                  <template slot-scope="{ row }">
                    <span v-if="row.last_at"> {{ row.last_at }} </span>
                    <span v-else> - </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
        </el-tabs>
      </div>
    </el-row>
  </div>
</template>

<script>
import APIs from '../../../APIs';
import { mapGetters } from 'vuex';
export default {
  data () {
    return {
      balance_loading: false,
      dataAccountInfo: {},
      loading_usageInfo: false,
      activeName: 'h24',
      tableDataByUsageInfo: []
    }
  },
  computed: {
    ...mapGetters('userToken', ['getToken'])
  },
  mounted () {
    this.homeAccountInfo();
    this.homeUsageInfo(this.activeName);
  },
  methods: {
    onCallUsageInfoTabClick(tab) {
      this.homeUsageInfo(tab.name);
    },
    homeAccountInfo() {
      const self = this;
      self.balance_loading = true;
      self.dataAccountInfo = {};
      self.$http.get(APIs.HOME_ACCOUNT_INFO)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.message)
          self.dataAccountInfo = data.data;
          // console.log(self.dataAccountInfo);
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.balance_loading = false })
    },
    homeUsageInfo(timeInterval) {
      const self = this;
      self.loading_usageInfo = true;
      self.tableDataByUsageInfo = [];
      self.$http.get(APIs.HOME_USAGE_INFO + '?time_interval=' + timeInterval)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.message)
          self.tableDataByUsageInfo = data.data;
          /// console.log(self.tableDataByUsageInfo);
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.loading_usageInfo = false })
    }
  }
}
</script>

<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.v-customer-home-index {
  min-height: 200px;
  margin: 15px;
  background: #fff;
  .left-border {
    margin-left: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    border-left: 3px solid #ccc;
  }
  .home-col {
    padding-left: 10px;
    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    /*余额样式*/
    .balance-box {
      border-radius: 10px;
      box-shadow: 1px 1px 15px -2px #ccc;
      .box-header {
        padding: 0 10px;
        line-height: 50px;
        background-color: #f2f2f2;
      }
      .box-body {
        text-align: center;
        .balance-cash {
          margin: 20px 0;
          font-size: 35px;
          font-weight: bold;
        }
      }
    }
    /*余额样式 END*/
    .newest-order {
      margin-top: 30px;
    }
  }
  /*带阴影的盒子样式*/
  .shadow-box {
    padding: 5px;
    border-radius: 10px;
    box-shadow: 1px 1px 15px -2px #ccc;
  }
  /*带阴影的盒子样式 END*/
  .recharge-qrcode {
    width: 100%;
    text-align: center;
    img {
      width: 50%;
    }
  }
}
</style>