/* 超管权限菜单配置列表 */
export default [
  {
    name: '/mask/console',
    icon: 'el-icon-menu',
    text: '总览',
    key: 'all'
  },
  // {
  //   name: '/mask/console/secret-key',
  //   icon: 'el-icon-service',
  //   text: '密钥管理',
  //   key: 'secret'
  // },
  // {
  //   name: '/mask/console/material',
  //   icon: 'el-icon-menu',
  //   text: '素材管理',
  //   key: 'material',
  //   submenus: [
  //     {
  //       name: '/mask/console/material/2D',
  //       icon: 'el-icon-star-on',
  //       text: '2D素材'
  //     },
  //     {
  //       name: '/mask/console/material/3D',
  //       icon: 'el-icon-star-off',
  //       text: '3D素材'
  //     }
  //   ]
  // },
  {
    name: '/mask/console/wxset',
    icon: 'el-icon-menu',
    text: '小程序管理',
    key: 'wxset',
    submenus: [
      {
        name: '/mask/console/wxset/home',
        icon: 'el-icon-document',
        text: '首页'
      },
      {
        name: '/mask/console/wxset/knowledge',
        icon: 'el-icon-document',
        text: '口罩小知识'
      },
      {
        name: '/mask/console/wxset/capture_notice',
        icon: 'el-icon-document',
        text: '人脸采集须知'
      }
      // ,
      // {
      //   name: '/mask/console/wxset/me',
      //   icon: 'el-icon-view',
      //   text: '我的'
      // }
    ]
  },
  // {
  //   name: '/tryon-open/customer/order',
  //   icon: 'el-icon-document',
  //   text: '服务订单',
  //   key: 'order'
  // },
  // {
  //   name: '/mask/console/finance',
  //   icon: 'el-icon-tickets',
  //   // text: '财务管理',
  //   text: '数据管理',
  //   key: 'finance'
  // },
  {
    name: '/mask/console/user',
    icon: 'el-icon-tickets',
    text: '用户管理',
    key: 'user'
  },
  {
    name: '/mask/console/analysis',
    icon: 'el-icon-tickets',
    text: '推荐管理',
    key: 'analysis'
  },
  // {
  //   name: '/tryon-open/recharge',
  //   icon: 'el-icon-goods',
  //   text: '充值服务',
  //   key: 'recharge'
  // },
  // {
  //   name: '/tryon-open/customer/userInfo',
  //   icon: 'el-icon-view',
  //   text: '个人信息',
  //   key: 'userInfo'
  // },
  {
    name: '/mask/console/info',
    icon: 'el-icon-view',
    text: '登录信息',
    key: 'userInfo'
  }
  // {
  //   name: '/sdk',
  //   icon: 'el-icon-question',
  //   text: 'SDK文档',
  //   key: 'sdk'
  // }
];