/**
 * debug vuex
 * Author: Zeon
 * Date: 2017/8/1
 * Created by Panxsoft.
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
    <h1>Debugging Vuex</h1>
</template>
<script>

import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'

export default {

    data () {
        return {

        }
    },

    mounted () {
        console.log(this.test)
        console.log(this.setStaffTest())
        console.log(this.getTest)
        console.log(this.fetchTest)
    },

    computed: {
        // 有命名空间的 vuex
        ...mapState('test', [
             'test'
        ]),
        ...mapGetters('test', [
            'getTest'
        ])
    },

    methods: {
        ...mapActions('test', [
            'fetchTest'
        ]),
        ...mapMutations('test', {
            setStaffTest: 'setTest'
        })
    }
}
</script>