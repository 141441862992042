/*
 * 服务价格编辑
 * @Author: LuoQianJun
 * @Date: 2018-05-02 09:26:11
 * @Last Modified by: LuoQianJun
 * @Last Modified time: 2018-05-02 15:06:40
 */
<style lang="less" rel="stylesheet/less" type="text/less">

    .admin-bym-service-price-edit {
         .el-input-number .el-input__inner {
            text-align: left;
        }
    }

</style>

<template>
    <div class="admin-bym-service-price-edit">
        <el-form :model="formValidate" ref="formValidate" label-width="150px" class="demo-ruleForm">
            <el-form-item label="服务产品类型" prop="service_id">
                <el-col :span="8">
                    <el-select v-model="formValidate.service_id" placeholder="请选择服务产品类型" style="width:100%;">
                        <el-option  v-for="(service) in services"
                                    :key="service.id"
                                    :label="service.name"
                                    :value="service.id">
                        </el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="服务产品名称" prop="type">
                 <el-col :span="8">
                    <el-input v-model="formValidate.type" placeholder="请输入服务产品名称"></el-input>
                 </el-col>
            </el-form-item>

            <el-form-item label="服务产品简介" prop="remark">
                 <el-col :span="8">
                    <el-input v-model="formValidate.remark" placeholder="请输入服务产品简介"></el-input>
                 </el-col>
            </el-form-item>

            <el-form-item label="服务产品价格" prop="charge">
                 <el-col :span="8">
                    <el-input-number v-model="formValidate.charge" :min="0" placeholder="请输入服务产品价格" controls-position="right" style="width:100%;"></el-input-number>
                 </el-col>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="updateMaterial">确定</el-button>
            </el-form-item>
        </el-form>

    </div>
</template>

<script>

import API_LIST from '../../../API_LIST';

export default {

    name: 'admin-bym-service-price-edit',

    props: ['serviceDetail', 'services'],
    data() {
        return {
            formValidate: {
                service_id: '',
                name: '',
                remark: '',
                charge: 0
            }
        }
    },

    watch: {
        serviceDetail: function() {
            if (this.serviceDetail) {
                this.initialValue()
            }
        }
    },

    methods: {

        /**
         * 初始值
         * @return {undefined}
         */
        initialValue() {
            this.formValidate.type = this.serviceDetail.type
            this.formValidate.remark = this.serviceDetail.remark
            this.formValidate.service_id = this.serviceDetail.service_id
            this.formValidate.charge = this.serviceDetail.charge
        },
        /**
         * 更新信息
         * @return {undefined}
         */
        updateMaterial() {
            const form_data = {
                _method: 'PUT',
                service_id: this.formValidate.service_id,
                type: this.formValidate.type,
                remark: this.formValidate.remark,
                charge: this.formValidate.charge
            }
            this.$emit('update', this.serviceDetail.id, form_data)
        }
    }
}
</script>