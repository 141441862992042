/*
 * 充值主视图
 * Author: ljb
 * Date: 2018-04-26 10:07:30
 * Last Modified by: ljb
 * Last Modified time: 2018-04-26 10:56:25
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

    .v-customer-recharge-views {
        .recharge-qrcode {
            width: 100%;
            text-align: center;
            img {
                width: 50%;
            }
        }
    }

</style>
<template>
<div class="v-customer-recharge-views">

    <el-tabs v-model="tab_name" type="card">

        <el-tab-pane label="充值" name="充值">

            <recharge-create    :pay-types="pay_types"
                                v-loading="recharge_loading"
                                @post="confirmRecharge"></recharge-create>

        </el-tab-pane>

        <el-tab-pane label="查看" name="查看">

            <recharge-index></recharge-index>

        </el-tab-pane>

    </el-tabs>

    <!-- 对话框 -->
    <el-dialog  :title="dialog_title"
                :visible.sync="show_dialog"
                width="600px">

        <div class="recharge-qrcode">
            <img :src="recharge_qrcode" alt="">
        </div>

    </el-dialog>

</div>
</template>

<script>

import API_LIST from '../../../API_LIST'

// import qs from "qs"

import rechargeCreate from './create'
import rechargeIndex from './index'

export default {
    name: 'recharge-views',
    data () {
        return {

            show_dialog: false, // 显示弹框

            dialog_title: '',

            recharge_loading: false, // 充值的loading

            recharge_qrcode: '', // 充值的二维码
            tab_name: '充值',

            pay_types: []

        }
    },
    components: {
        rechargeCreate,
        rechargeIndex
    },
    mounted () {
        this.fetchPayMethod()
    },
    methods: {

        /**
         * 获取支付的方式
         * @return {undefined}
         */
        fetchPayMethod () {
            this.recharge_loading = true

            return this.$http.get(API_LIST.ADMIN_RECHARGE + '/create')
                            .then(({ data }) => {
                                if (parseInt(data.code) !== 0) throw new Error(data.error)
                                this.pay_types = data.pay_types
                            })
                            .catch((err) => {
                                this.$message({
                                    type: 'error',
                                    message: err.message || err.statusText
                                })
                            })
                            .then(() => { this.recharge_loading = false })
        },
        /**
         * 确认生成充值二维码
         * @return {undefined}
         */
        confirmRecharge (param) {
            const self = this
            this.recharge_loading = true
            this.$http.post(API_LIST.ADMIN_RECHARGE, param)
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.recharge_qrcode = 'data:image/png;base64,' + data.data.qr_code
                        switch (parseInt(param.pay_type_id)) {
                            case 1:
                                self.dialog_title = '请用微信扫描完成充值'
                                break
                            default:
                                self.dialog_title = '请用支付宝扫描完成充值'
                                break
                        }
                        this.show_dialog = true
                    })
                    .catch((err) => {
                        console.log(err)
                        this.$message({
                          type: 'error',
                          message: err.message || err.statusText
                        })
                    })
                    .then(() => { this.recharge_loading = false })
        }
    }
}
</script>