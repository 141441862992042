import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './vuex/store'
import App from './App.vue'

import axios from './utils/axios-interceptors'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import './assets/css/common.css'

Vue.use(VueRouter)
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$http = axios

new Vue({
    el: '#app',
    router: router,
    store,
    render: h => h(App)
})