/** 财务流水列表 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.table-header {
  background-color: #f2f2f2;
}
.el-table-column-expand {
  width: 100px;
  color: #99a9bf;
  padding: 0 12px 0 0;
  line-height: 36px;
  font-weight: 700;
  display: inline-block;
}
.wxavatar {
  background-color: red;
  width: 50px;
  height: 50px;
}
</style>

<template>
  <el-table :data="data" border height="600" v-loading="loading">
    <el-table-column prop="openid" label="微信openid" align="center" width="145px"></el-table-column>
    <el-table-column label="微信头像" align="center" width="100px">
      <template slot-scope="{ row }">
        <img v-if="row.userInfo && row.userInfo.avatar" :src="row.userInfo.avatar" alt="" class="wxavatar">
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column label="微信昵称" align="center" width="100px">
      <template slot-scope="{ row }">
        <span v-if="row.userInfo && row.userInfo.nickname">{{ row.userInfo.nickname }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column label="姓名" align="center" width="100px">
      <template slot-scope="{ row }">
        <span v-if="row.userInfo && row.userInfo.username">{{ row.userInfo.username }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column label="性别" align="center" width="60px">
      <template slot-scope="{ row }">
        <span v-if="row.userInfo && row.userInfo.gender && row.userInfo.gender === 0">保密</span>
        <span v-else-if="row.userInfo && row.userInfo.gender && row.userInfo.gender === 1">男</span>
        <span v-else-if="row.userInfo && row.userInfo.gender && row.userInfo.gender === 2">女</span>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column label="体重" align="center" width="60px">
      <template slot-scope="{ row }">
        <span v-if="row.userInfo && row.userInfo.weight">{{ row.userInfo.weight }} kg</span>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column label="籍贯(省市)" align="center">
      <template slot-scope="{ row }">
        <span v-if="row.userInfo && row.userInfo.province && row.userInfo.city && row.userInfo.district">
          <label>{{ row.userInfo.province ? row.userInfo.province : '*' }} / {{ row.userInfo.city ? row.userInfo.city : '*' }} / {{ row.userInfo.district ? row.userInfo.district : '*' }}</label>
        </span>
        <span v-else>
          <label>-</label>
        </span>
      </template>
    </el-table-column>
    <el-table-column label="详细地址" align="center">
      <template slot-scope="{ row }">
        <span v-if="row.userInfo && row.userInfo.address">{{ row.userInfo.address }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column label="手机号码" align="center" width="120px">
      <template slot-scope="{ row }">
        <span v-if="row.userInfo && row.userInfo.phone">{{ row.userInfo.phone }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column label="推荐数据" align="center" span="2">
      <el-table-column label="总量" align="center" width="80px">
        <template slot-scope="{ row }">
          <span v-if="row.analysis_count && row.analysis_count > 0"> {{ row.analysis_count }} </span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="180px">
        <template slot-scope="{ row }">
          <span v-if="row.analysis_count && row.analysis_count > 0">
            <el-button type="primary" icon="el-icon-search" size="mini" @click="searchAnalysis(row)">查看</el-button>
            <el-button type="primary" icon="el-icon-download" size="mini" @click="downloadAnalysis(row)">下载</el-button>
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script>
import APIs from '../../../APIs';
import { mapMutations } from 'vuex';
export default {
    name: 'wxuser-table',
    props: ['data', 'loading'],
    methods: {
      ...mapMutations('menu', ['setSelectedMenu']),
      ...mapMutations('wxuserData', ['setWxuserSearch']),
      searchAnalysis(e) {
        // console.log(e);
        const gotoPath = '/mask/console/analysis';
        this.setSelectedMenu(gotoPath);
        this.setWxuserSearch(e);
        this.$router.push({ path: gotoPath });
      },
      downloadAnalysis(e) {
        // console.log(e);
        if (e !== undefined && e !== null && e.openid !== undefined && e.openid !== null) {
          const self = this;
          let fileName = e.openid + '_analysis_data.xls'; // 文件名
          let formData = new FormData();
          formData.append('user', e.openid);
          self.$http.post(APIs.ANALYSIS_XLS, formData, { responseType: 'blob' })
            .then((response) => {
              // console.log(response);
              if (parseInt(response.status) !== 200) throw new Error('下载失败');
              let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' }); // 创建Blob对象
              if (window.navigator.msSaveOrOpenBlob) { // 对于IE浏览器使用msSaveOrOpenBlob方法
                window.navigator.msSaveOrOpenBlob(blob, fileName);
              } else {
                let a = document.createElement('a');
                a.setAttribute('href', window.URL.createObjectURL(blob));
                a.setAttribute('target', '_blank');
                a.setAttribute('download', fileName);
                a.click();
              }
            })
            .catch((err) => {
              /// self.$message({
              ///   type: 'error',
              ///   message: err.message || err.statusText
              /// })
              console.log(err.message || err.statusText);
              self.$message({
                type: 'error',
                message: '下载失败'
              })
            })
            .then(() => { })
        }
      }
    }
}
</script>