/*
 * 修改服务状态的edit组件
 * Author: ljb
 * Date: 2018-04-27 16:26:44
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 17:13:21
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
<el-form :model="form_data" class="demo-form-inline">
    <el-form-item label="服务状态">

        <el-radio-group v-model="form_data.service_status">
            <el-radio label="1">启用</el-radio>
            <el-radio label="0">禁用</el-radio>
        </el-radio-group>

    </el-form-item>
    <el-form-item label="计费价格" v-if="!canRevisePrice">

        <el-input-number v-model="form_data.single_billing" :min="0" label="计费价格"></el-input-number>

    </el-form-item>
    <el-form-item>
        <el-button type="primary" @click="$emit('update',serviceDetail.id,form_data)">确定</el-button>
    </el-form-item>
</el-form>
</template>

<script>
export default {
    props: ['serviceDetail'],
    name: 'service-edit',
    data: function() {
        return {
            form_data: {
                _method: 'PUT',
                service_status: '',
                single_billing: 0.00
            }
        }
    },
    watch: {
        serviceDetail: function() {
            if (this.serviceDetail) {
                this.form_data.service_status = String(this.serviceDetail.service_status)
                this.form_data.single_billing = parseFloat(this.serviceDetail.single_billing)
            }
        }
    },
    computed: {
        canRevisePrice() {
            if (!this.serviceDetail) return
            /// return parseInt(this.serviceDetail.service_status) === 1 ? true : false
            return parseInt(this.serviceDetail.service_status) === 1
        }
    },
    methods: { }
}
</script>