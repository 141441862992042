import { render, staticRenderFns } from "./views-new.vue?vue&type=template&id=60a0b442&scoped=true&"
import script from "./views-new.vue?vue&type=script&lang=js&"
export * from "./views-new.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a0b442",
  null
  
)

export default component.exports