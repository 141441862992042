/*
 * 微信小程序页面设置
 * Author: ljb
 * Date: 2018-04-25 09:26:11
 * Last Modified by: ljb
 * Last Modified time: 2018-04-25 10:15:31
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>

    <!-- 管理内容区子路由视图 -->
    <router-view></router-view>

</template>

<script>
export default {
    name: 'wxset-index',
    data () {
        return { }
    },
    methods: { }
}
</script>