/* 财务管理主视图 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>
  <div class="v-customer-wxuser-views">
    <el-tabs v-model="tab_name" type="card">
      <el-tab-pane label="用户数据" name="用户数据">
        <div style="margin-bottom:10px;">
          <el-button type="primary" icon="el-icon-download" @click="confirmDownload">下载全部用户数据</el-button>
        </div>
        <table-index :data="wxuserData"
                    :loading="wxuser_list_loading"></table-index>
        <br />
        <el-pagination  align="center"
                        background
                        @size-change="perPageChangeByWxuserData"
                        @current-change="pageChangeByWxuserData"
                        :current-page.sync="wxuser_data.pageNo"
                        :page-size="pageQueryInfoByWxuser.pageSize"
                        layout="sizes, prev, pager, next"
                        :total="wxuser_data.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import APIs from '../../../APIs';
import tableIndex from './wxuser-table';
import { mapMutations } from 'vuex';
import { getUuid, getTimeId } from '../../../utils/filename.js';
export default {
  name: 'wxuser_index',
  data: function() {
    return {
      tab_name: '用户数据',
      pageQueryInfoByWxuser: {
        pageNo: 1,
        pageSize: 20
      },
      pageSearchInfoByWxuser: {
        pageNo: 1,
        pageSize: 20
      },
      wxuser_list_loading: false,
      wxuser_data: {}
    }
  },
  components: {
    tableIndex
  },
  mounted () {
    this.fetchWxuser(this.pageQueryInfoByWxuser);
    this.setWxuserSearch(null);
    // var fileName1 = getUuid(8, 12)
    // var fileName2 = getTimeId(8, 12)
    // console.log(fileName1, 'fileName1', fileName2, 'fileName2')
  },
  computed: {
    wxuserData() {
      return this.wxuser_data.list || [];
    }
  },
  methods: {
    ...mapMutations('wxuserData', ['setWxuserSearch']),
    pageChangeByWxuserData(pageNo) {
      this.pageSearchInfoByWxuser.pageNo = pageNo;
      this.pageSearchInfoByWxuser.pageSize = this.pageQueryInfoByWxuser.pageSize;
      this.fetchWxuser(this.pageSearchInfoByWxuser);
    },
    perPageChangeByWxuserData(pageSize) {
      this.pageQueryInfoByWxuser.pageSize = pageSize;
      this.pageSearchInfoByWxuser.pageSize = pageSize;
      this.fetchWxuser(this.pageSearchInfoByWxuser);
    },
    fetchWxuser(param) {
      const self = this;
      self.wxuser_list_loading = true;
      let formData = new FormData();
      formData.append('pageNo', param.pageNo);
      formData.append('pageSize', param.pageSize);
      self.$http.post(APIs.USERS_DATA, formData)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.message);
          self.wxuser_data = data.data;
          // console.log(wxuser_data);
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.wxuser_list_loading = false })
    },
    confirmDownload() {
      const self = this;
      self.$http.get(APIs.USERS_XLS, { responseType: 'blob' })
        .then((response) => {
          // console.log(response);
          if (parseInt(response.status) !== 200) throw new Error('下载失败');
          let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' }); // 创建Blob对象
          let fileName = getTimeId(8, 12) + '_wxuser_data.xls'; // 文件名
          if (window.navigator.msSaveOrOpenBlob) { // 对于IE浏览器使用msSaveOrOpenBlob方法
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            let a = document.createElement('a');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.setAttribute('target', '_blank');
            a.setAttribute('download', fileName);
            a.click();
          }
        })
        .catch((err) => {
          /// self.$message({
          ///   type: 'error',
          ///   message: err.message || err.statusText
          /// })
          console.log(err.message || err.statusText);
          self.$message({
            type: 'error',
            message: '下载失败'
          })
        })
        .then(() => { })
    }
  }
}
</script>