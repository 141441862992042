/// const HOST_URL = 'http://localhost:8111';
// const HOST_URL = 'http://localhost:8318';
// const HOST_URL = 'http://127.0.0.1:8318';
// const HOST_URL = 'http://127.0.0.1:8318';
const HOST_URL = 'https://dggdc.findmyn95.com:4433';

const ADMIN_LOGIN = HOST_URL + '/admin/v1/login';

const HOME_ACCOUNT_INFO = HOST_URL + '/admin/v1/account-info';
const HOME_USAGE_INFO = HOST_URL + '/admin/v1/usage-info';

const ADMIN_USER_INFO = HOST_URL + '/admin/v1/admin-info';

const USERS_DATA = HOST_URL + '/admin/v1/users-page';
const USERS_XLS = HOST_URL + '/admin/v1/users-xls';
const ANALYSIS_DATA = HOST_URL + '/admin/v1/analysis-page';
const ANALYSIS_XLS = HOST_URL + '/admin/v1/analysis-xls';

const WXSET_HOME_CONTENT_DATA = HOST_URL + '/admin/v1/wxset-home-content';
const WXSET_KNOWLEDGE_DATA = HOST_URL + '/admin/v1/wxset-knowledge';
const WXSET_CAPTURE_NOTICE_DATA = HOST_URL + '/admin/v1/wxset-capture-notice';

const UPLOAD_FILE = HOST_URL + '/admin/v1/upload';

const ADMIN_ACCESS = HOST_URL + '/admin/v1/access';
const GLASSES_2D_ASSET = HOST_URL + '/admin/v1/g2d-page';
const GLASSES_2D_MATERIAL = HOST_URL + '/admin/v1/g2d';
const GLASSES_3D_ASSET = HOST_URL + '/admin/v1/g3d-page';
const GLASSES_3D_MATERIAL = HOST_URL + '/admin/v1/g3d';
const ORDER_DATA_FLOW = HOST_URL + '/admin/v1/order/data-flow';
const ORDER_DATA_BALANCE = HOST_URL + '/admin/v1/order/data-balance';

export default {
  ADMIN_LOGIN,
  HOME_ACCOUNT_INFO,
  HOME_USAGE_INFO,
  ADMIN_USER_INFO,
  USERS_DATA,
  USERS_XLS,
  ANALYSIS_DATA,
  ANALYSIS_XLS,
  WXSET_HOME_CONTENT_DATA,
  WXSET_KNOWLEDGE_DATA,
  WXSET_CAPTURE_NOTICE_DATA,
  UPLOAD_FILE,
  ADMIN_ACCESS,
  GLASSES_2D_ASSET,
  GLASSES_2D_MATERIAL,
  GLASSES_3D_ASSET,
  GLASSES_3D_MATERIAL,
  ORDER_DATA_FLOW,
  ORDER_DATA_BALANCE
}