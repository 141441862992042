/*
 * 资金流水视图
 * @Author: ljb
 * @Date: 2018-04-27 11:12:06
 * @Last Modified by: ljb
 * @Last Modified time: 2018-04-27 14:55:12
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
<div class="v-admin-order-flow-views">

    <el-tabs v-model="tab_name" type="card">

        <el-tab-pane label="资金流水" name="资金流水">
            <!-- 搜索组件 -->
            <search-form @search="confirmSearch"></search-form>
            <!-- 资金流水组件 -->
            <flow-index :data="financeFlow"
                        :loading="flow_list_loading"></flow-index>

            <br>
            <!-- 分页组件 -->
            <el-pagination  align="center"
                            background
                            @size-change="perPageChange"
                            @current-change="pageChange"
                            :current-page.sync="cash_flows.current_page"
                            :page-size="query_info.per_page"
                            layout="sizes, prev, pager, next"
                            :total="cash_flows.total">
            </el-pagination>

        </el-tab-pane>

    </el-tabs>

</div>
</template>

<script>

import API_LIST from '../../../../API_LIST';

import qs from 'qs';

import flowIndex from './index';
import searchForm from './search-form';

export default {
    name: 'order-flow-views',
    data: function() {
        return {
            tab_name: '资金流水',
            query_info: {
                page: 1,
                per_page: 20,
                balance_type: '',
                time_range: []
            }, // 查询信息
            search_info: {
                page: 1,
                search_infoper_page: 20
            }, // 分页信息
            flow_list_loading: false, // 财务流水loading
            cash_flows: {} // 财务流水
        }
    },
    components: {
        flowIndex,
        searchForm
    },
    mounted () {
        this.fetchFinanceFlow(this.query_info)
    },
    computed: {
        financeFlow() {
            return this.cash_flows.data || []
        }
    },
    methods: {

        /**
         * 页码改变
         * @param  {string} pageNumber {当前页码}
         * @return {undefined}
         */
        pageChange(pageNumber) {
            this.search_info.page = pageNumber
            this.search_info.per_page = this.query_info.per_page
            this.fetchFinanceFlow(this.search_info)
        },

        /**
         * 每页显示数改变
         * @param  {string} per_page {当前每页显示数}
         * @return {undefined}
         */
        perPageChange(per_page) {
            this.search_info.per_page = per_page
            this.query_info.per_page = per_page
            this.fetchFinanceFlow(this.search_info)
        },

        /**
         * 确认搜索
         * @param  {string} balance_type {财务类型}
         * @param  {array} time_range   {时间区间}
         * @return {undefined}
         */
        confirmSearch({ balance_type, time_range }) {
            this.query_info.balance_type = balance_type || ''
            this.query_info.time_range = time_range || []
            this.search_info = JSON.parse(JSON.stringify(this.query_info))
            this.fetchFinanceFlow(this.query_info)
        },

        /**
         * 财务列表
         * @return {undefined}
         */
        fetchFinanceFlow(param) {
            this.flow_list_loading = true
            const query_str = qs.stringify(param)
            this.$http.get(API_LIST.BYM_FLOW + '?' + query_str)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.cash_flows = data.cash_flows
                })
                .catch((err) => {
                    console.log(err)
                    this.$message({
                        type: 'error',
                        message: err.message || err.statusText
                    })
                })
                .then(() => { this.flow_list_loading = false })
        }
    }
}
</script>