/** 密钥管理 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>
  <div class="secret-key-index">
    <div v-for="(item, index) in thisApiUser" :key="index">
      <el-form status-icon label-width="100px" class="demo-ruleForm">
        <el-form-item label="api_key：">
          <el-col :span="12">
            <el-input v-model="item.api_key" placeholder="" readonly></el-input>
          </el-col>
        </el-form-item>
         <el-form-item label="api_secret：">
          <el-col :span="12">
            <el-input v-model="item.api_secret" placeholder="" readonly></el-input>
          </el-col>
        </el-form-item>
        <span v-if="thisApiUser !== undefined && thisApiUser !== null && index < thisApiUser.length - 1">
          <br />
          <hr />
          <br />
          <br />
        </span>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'secret-key-index',
  props: ['apiUser'],
  data () {
    return {
      thisApiUser: this.apiUser
    }
  },
  watch: {
    apiUser: function () {
      this.thisApiUser = this.apiUser;
    }
  },
  created () { },
  methods: { }
}
</script>