import { render, staticRenderFns } from "./wxset_home.vue?vue&type=template&id=cc974026&scoped=true&"
import script from "./wxset_home.vue?vue&type=script&lang=js&"
export * from "./wxset_home.vue?vue&type=script&lang=js&"
import style0 from "./wxset_home.vue?vue&type=style&index=0&id=cc974026&prod&lang=less&rel=stylesheet%2Fless&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc974026",
  null
  
)

export default component.exports