/** 3D素材列表 */
<style lang="less" rel="stylesheet/less" type="text/less">
.customer-material-3D-index {
  .el-table th>.cell , .el-table .cell {
    text-align: center;
  }
}
</style>

<template>
  <div class="customer-material-3D-index" >
    <el-table align="center" :data="materials" style="width: 100%" border v-loading="loading">
      <el-table-column prop="name" label="素材名称"></el-table-column>
      <el-table-column prop="uuid" label="素材编码"></el-table-column>
      <el-table-column prop="model" label="素材地址"></el-table-column>
      <el-table-column prop="created_at" label="创建时间" width="100"></el-table-column>
      <el-table-column prop="updated_at" label="更新时间" width="100"></el-table-column>
      <el-table-column label="操作" width="175">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'admin-material-3D-index',
  props: ['materials', 'loading'],
  data: function() {
    return { }
  },
  methods: {
    /** 3D素材删除 */
    handleDelete(record) {
      this.$emit('delete', record.id)
    },
    /** 3D素材编辑 */
    handleEdit(record) {
      this.$emit('edit', record)
    }
  }
}
</script>