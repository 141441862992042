/*
 * 搜索框
 * Author: ljb
 * Date: 2018-04-27 14:17:42
 * Last Modified by: ljb
 * Last Modified time: 2018-04-27 16:06:36
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>
<template>
<el-form :inline="true" :model="form_data" class="demo-form-inline">
    <el-form-item label="财务类型">
        <el-select v-model="form_data.balance_type" clearable placeholder="请选择">
            <el-option  v-for="balance_type in balance_types"
                        :key="balance_type.value"
                        :label="balance_type.label"
                        :value="balance_type.value">
            </el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="起止日期">
        <el-date-picker v-model="form_data.time_range"
                        type="daterange"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
        </el-date-picker>
    </el-form-item>
    <el-form-item>
        <el-button type="primary" @click="$emit('search',form_data)">查询</el-button>
    </el-form-item>
</el-form>
</template>

<script>
export default {
    name: 'search-form',
    data: function() {
        return {
            balance_types: [
                {
                    label: '充值',
                    value: 1
                },
                {
                    label: '支出',
                    value: 2
                }
            ],
            form_data: {
                balance_type: '',
                time_range: []
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    picker.$emit('pick', [start, end])
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    picker.$emit('pick', [start, end])
                    }
                }]
            } // element-ui带快捷选项的时间picker选项
        }
    },
    methods: {}
}
</script>