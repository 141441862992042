// 检查密码是否为空
export const validatePass = (rule, value, callbackFunc) => {
    if (value === '') {
        callbackFunc('请输入密码');
    } else {
        callbackFunc();
    }
};

/**
 * 校验新密码不能和旧密码一样
 * @param  {Object} rule     {传入的数据对象}
 * @param  {Object} value    {需要校验的value值}
 * @param  {function} callbackFunc {判断之后执行的回调}
 * @return {undefined}
 */
export const validateNewPwd = (rule, value, callbackFunc) => {
    if (value === '') {
        callbackFunc('请输入密码');
    } else {
        if (rule.pwd_info.formValidate.old_password === rule.pwd_info.formValidate.new_password) {
            callbackFunc('新旧密码不能一样');
        } else {
            callbackFunc();
        }
    }
};

// 检查密码是否一致
export const validatePassCheck = function (rule, value, callbackFunc) {
    if (value === '') {
        callbackFunc('请确认您的密码!');
    } else {
        if (rule.pwd_info.form_data.password !== value) {
            callbackFunc('两次输入密码不一致！');
        } else {
            callbackFunc();
        }
    }
};

// 校验host域名
export const validateHost = (rule, value, callbackFunc) => {
    // if (!/^((([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9]))$/.test(value)) {
    if (!/^((([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]))$/.test(value)) {
        callbackFunc(new Error('域名格式不正确'));
    } else {
        callbackFunc();
    }
};

// 校验ip
export const validateIp = (rule, value, callbackFunc) => {
    if (!/^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])(\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])){3}$/.test(value)) {
        callbackFunc(new Error('ip格式不正确'));
    } else {
        callbackFunc();
    }
};

// 检查电话号码格式（包括手机和座机）
export const validatePhone = (rule, value, callbackFunc) => {
    if (value === '') {
        if (rule.validateType === 'create') {
            callbackFunc(new Error('请输入电话号码'));
        } else {
            callbackFunc();
        }
    } else if (!/^1[356789]\d{9}$/.test(value)) {
        if (!/^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/.test(value)) {
            callbackFunc(new Error('电话格式不正确'));
        } else {
            callbackFunc();
        }
    } else {
        callbackFunc();
    }
};

// 检查是否是数字
export const validateNumber = (rule, value, callbackFunc) => {
    if (value === '') {
        callbackFunc('必填项');
    } else if (!Number(value) && Number(value) !== 0) {
        callbackFunc('必须是数字');
    } else {
        if (rule.maxValue) {
            if (parseInt(value) > rule.maxValue) {
                callbackFunc('超过最大限制值');
            } else {
                callbackFunc();
            }
        } else {
            callbackFunc();
        }
    }
};

// 检查是否选择了图片
export const validateImage = (rule, file, callbackFunc) => {
    if (file !== undefined && file !== null && typeof file === 'string' && file.substring(0, 4) === 'http') {
      callbackFunc();
      return;
    }
    let max_size;
    if (rule.maxSize) {
        max_size = rule.maxSize;
    } else {
        max_size = 2048;
    }
    if (!file || typeof file !== 'object') {
        callbackFunc('还未选择图片！');
    } else {
        let type = file.name.split('.')[1];
        let name = file.name.split('.')[0];
        // let ch_reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
        if ('jpg jpeg png'.split(' ').indexOf(type) === -1) {
            callbackFunc('只能选择文件后缀为jpg,jpeg,png的图片！');
        } else if (parseFloat(file.size) / 1024 > max_size) {
            callbackFunc('图片大小不能超过' + (max_size / 1024) + 'M！');
        } else {
            callbackFunc();
        }
    }
};

// 检查是否选择了模型
export const validateModel = (rule, file, callbackFunc) => {
    if (!file || typeof file !== 'object') {
        callbackFunc('请选择模型！');
    } else {
        callbackFunc();
    }
};

/**
 * @function validateFile
 * @param  {Object} rule     {可传递的参数对象}
 * @param  {Object} file     {file文件对象}
 * @param  {function} callbackFunc {校验的回调函数}
 * @return {undefined}
 */
export const validateUpdateFile = (rule, file, callbackFunc) => {
    let max_size;
    if (rule.maxSize) {
        max_size = rule.maxSize;
    } else {
        max_size = 1536;
    }
    if (file && typeof file === 'object') {
        let type = file.name.split('.')[1];
        //  let ch_reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
        if ('jpg jpeg png'.split(' ').indexOf(type) === -1) {
            callbackFunc('只能选择文件后缀为jpg,jpeg,png的图片！');
        } else if (parseFloat(file.size) / 1024 > max_size) {
            callbackFunc('图片大小不能超过' + (max_size / 1024) + 'M！');
        // } else if (ch_reg.test(name)) {
        } else if (/[\u4e00-\u9fa5]/g.test(name)) {
            callbackFunc('图片名字不能包含中文！');
        } else {
            callbackFunc();
        }
    } else {
        callbackFunc();
    }
};

// 检查城市是否为空
export const validateCityCheck = (rule, value, callbackFunc) => {
    if (value.length === 0) {
        callbackFunc('城市不能为空');
    } else {
        callbackFunc();
    }
};

// 检查多选是否为空
export const validateMulti = (rule, value, callbackFunc) => {
    if (value.length === 0) {
        callbackFunc('必选');
    } else {
        callbackFunc();
    }
};

// 检查数组里面每个对象的值是否为空
export const validateArrayValue = (rule, value, callbackFunc) => {
    if (value.length === 0) {
        callbackFunc('存有未填' + rule.name + '值');
    } else {
        if (!value.every((a) => a.name)) {
            callbackFunc('存有未填' + rule.name + '值');
        } else {
            callbackFunc();
        }
    }
};

// 检查域名数组里面每个对象的值是否为空
export const validateHostArrayValue = (rule, value, callbackFunc) => {
    if (value.length === 0) {
        callbackFunc('存在未填的域名');
    } else {
        if (!value.every((host) => host.value)) {
            callbackFunc('存在未填的域名');
        /// } else if (!value.every((host) => /^((([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9]))$/.test(host.value))) {
        } else if (!value.every((host) => /^((([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]))$/.test(host.value))) {
            callbackFunc('域名格式不正确');
        } else {
            callbackFunc();
        }
    }
};

// 检查IP数组里面每个对象的值是否为空
export const validateIpArrayValue = (rule, value, callbackFunc) => {
    if (value.length === 0) {
        // Unexpected literal in error position of callback
        callbackFunc('存在未填的ip');
    } else {
        if (!value.every((ip) => ip.value)) {
            callbackFunc('存在未填的ip');
        } else if (!value.every((ip) => /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])(\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])){3}$/.test(ip.value))) {
            callbackFunc('ip格式不正确');
        } else {
            callbackFunc();
        }
    }
};