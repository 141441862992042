// =====ok.all
import adminMenus from '../../views/admin/menus'
import customerMenus from '../../views/customer/menus'
/// import router from '../../router'

const user_type = sessionStorage.getItem('user_type')
const menu_title = sessionStorage.getItem('menu_title')

let menu = []
try {
  menu = JSON.parse(sessionStorage.getItem('menu'))
} catch (error) {
  menu = []
}

const selected_menu = sessionStorage.getItem('selected_menu') || ''

let opened_menu = []
try {
  opened_menu = JSON.parse(sessionStorage.getItem('opened_menu'))
} catch (error) {
  opened_menu = []
}

export default {
  namespaced: true,
  state: {
    user_type: user_type, // 权限
    menu_title: menu_title, // 菜单顶部的说明
    side_menus: menu,
    opened_menu: opened_menu, // 设置当前展开的子菜单name
    selected_menu: selected_menu
  },
  mutations: {
    setMenus (state, type) {
      state.user_type = type
      switch (type) {
        case 'customer':
          state.side_menus = customerMenus
          state.menu_title = '客户权限管理后台'
          break
        default:
          state.side_menus = adminMenus
          state.menu_title = '超管权限管理后台'
          break
      }
      sessionStorage.setItem('menu', JSON.stringify(state.side_menus))
      sessionStorage.setItem('menu_title', state.menu_title)
      sessionStorage.setItem('user_type', state.user_type)
    },
    setSelectedMenu (state, menu) {
      state.selected_menu = menu
      sessionStorage.setItem('selected_menu', menu)
    },
    setOpenSubmenu (state, menu) {
      state.opened_menu = menu
      sessionStorage.setItem('opened_menu', JSON.stringify(menu))
    },
    clearCache (state) {
      state.menu_title = ''
      state.selected_menu = ''
      state.opened_menu = []
      sessionStorage.clear()
    }
  },
  actions: { },
  getters: {
    getUserType (state) {
      return state.user_type
    },
    getMenuTitle (state) {
      return state.menu_title || '云管理后台'
    },
    getMenus (state) {
      return state.side_menus || []
    },
    getSelectedMenu (state) {
      return state.selected_menu || ''
    },
    getOpenedMenu (state) {
      return state.opened_menu || []
    }
  }
}