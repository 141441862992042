/*
 * debug测试页面
 * @Author: ljb
 * @Date: 2018-01-30 18:02:09
 * @Last Modified by: ljb
 * @Last Modified time: 2018-02-25 11:03:35
 */
<template>
<div>
    <!-- <Button type="primary" @click="modal1 = true">显示对话框</Button> -->
    <!-- <Modal
        v-model="modal1"
        title="普通的Modal对话框标题"
        @on-ok="ok"
        @on-cancel="cancel">
        <p>对话框内容</p>
        <p>对话框内容</p>
        <p>对话框内容</p>
    </Modal> -->

</div>
</template>
<script>
// import districts from 'src/districts'

export default {
    data () {
        return {
            modal1: false
        }
    },
    methods: {
        ok () {
            this.$Message.info('点击了确定')
        },
        cancel () {
            this.$Message.info('点击了取消')
        }
    },
    mounted () {
        alert('test')
    }
}
</script>