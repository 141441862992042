/*
 * 服务订单主视图
 * Author: yudong
 * Date: 2018-04-25 09:26:11
 * Last Modified by: ljb
 * Last Modified time: 2018-04-28 15:59:05
 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

    .v-customer-order-views {
        padding: 20px;
        /*购买服务按钮*/
        .order {
            width: 150px;
            height: 35px;
            line-height: 35px;
            border: 1px solid #66CBFF;
            border-radius: 5px;
            color: #fff;
            text-align: center;
            background-color: #66CBFF;
            margin-bottom: 20px;
            cursor: pointer;
        }
        /*购买服务按钮END*/
    }

</style>
<template>
<div class="v-customer-order-views">

    <el-tabs v-model="tab_name" type="card">

        <el-tab-pane label="服务订单" name="服务订单">

            <div class="order" @click="buyBilling">订购服务</div>

            <!-- 服务订单列表 -->
            <order-index    :loading="service_list_loading"
                            :data="user_services.data"></order-index>

            <br>
            <el-pagination  align="center"
                            background
                            @size-change="perPageChange"
                            @current-change="pageChange"
                            :current-page.sync="user_services.current_page"
                            :page-size="query_info.per_page"
                            layout="sizes, prev, pager, next"
                            :total="user_services.total">
            </el-pagination>
            <!-- 分页信息 -->
        </el-tab-pane>

    </el-tabs>

    <!-- 对话框 -->
    <el-dialog  :title="dialog_title"
                :visible.sync="show_dialog"
                :width="dialog_width"
                v-loading="dialog_loading">

        <order-create   v-if="show_dialog"
                        :billing-types="billing_types"
                        :balance="user_balance"
                        @post="postData"></order-create>

    </el-dialog>

</div>
</template>

<script>

import API_LIST from '../../../API_LIST'

import orderCreate from './create'
import orderIndex from './index'

import qs from 'qs'

export default {

    name: 'order-views',

    components: {
        orderCreate,
        orderIndex
    },

    data () {
        return {

            dialog_loading: false,

            show_dialog: false,

            dialog_title: '',

            dialog_width: '600px', // 上四项均为弹出框的配置

            tab_name: '服务订单', // 默认的tab

            query_info: {

                page: 1,

                per_page: 20
            },

            search_info: {

                page: 1,

                per_page: 20

            },

            user_balance: 0.00, // 用户余额

            service_list_loading: false, // 列表更新时的loading

            user_services: {}, // 购买服务的订单

            billing_types: [] // 服务产品

        }
    },

    mounted () {
        this.fetchUserBalance()
        this.fetchTryOnType()
        this.fetchOrderList(this.query_info)
    },
    methods: {

        /**
         * 页码改变
         * @param  {string} pageNumber {当前页码}
         * @return {undefined}
         */
        pageChange (pageNumber) {
            this.search_info.page = pageNumber
            this.search_info.per_page = this.query_info.per_page
            this.fetchOrderList(this.search_info)
        },

        /**
         * 每页显示数改变
         * @param  {string} per_page {当前每页显示数}
         * @return {undefined}
         */
        perPageChange (per_page) {
            this.search_info.per_page = per_page
            this.query_info.per_page = per_page
            this.fetchOrderList(this.search_info)
        },

        /**
         * 调出订购服务的模态框
         * @return {undefined}
         */
        buyBilling () {
            this.dialog_title = '订购服务'
            this.show_dialog = true
        },

        /**
         * 订购服务的post
         * @param  {Object} param {post传递的参数}
         * @return {undefined}
         */
        postData (param) {
            this.dialog_loading = true
            this.$http.post(API_LIST.ADMIN_SERVICE, param)
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.$message.success('订购成功！')
                        this.show_dialog = false
                        this.fetchUserBalance()
                        this.fetchOrderList(this.search_info)
                    })
                    .catch((err) => {
                        console.log(err)
                        this.$message({
                            type: 'error',
                            message: err.message || err.statusText
                        })
                    })
                    .then(() => { this.dialog_loading = false })
        },

        /**
         * 获取试戴类型
         * @return {undefined}
         */
        fetchTryOnType () {
            this.$http.get(API_LIST.ADMIN_SERVICE + '/create')
                    .then(({ data }) => {
                        if (parseInt(data.code) !== 0) throw new Error(data.error)
                        this.billing_types = data.billing_types
                    })
                    .catch((err) => {
                        this.$message({
                            type: 'error',
                            message: err.message || err.statusText
                        })
                    })
        },

        /**
         * 获取用户余额
         * @return {undefined}
         */
        fetchUserBalance () {
            this.$http.get(API_LIST.ADMIN_BALANCE)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.user_balance = data.balance
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err.message || err.statusText
                    })
                })
        },

        /**
         * 获取订单列表
         * @param  {Object} param {搜索列表的参数}
         * @return {undefined}
         */
        fetchOrderList (param) {
            this.service_list_loading = true
            const query_str = qs.stringify(param)
            this.$http.get(API_LIST.ADMIN_SERVICE + '?' + query_str)
                .then(({ data }) => {
                    if (parseInt(data.code) !== 0) throw new Error(data.error)
                    this.user_services = data.api_user_services
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err.message || err.statusText
                    })
                })
                .then(() => { this.service_list_loading = false })
        }
    }
}
</script>