/** 路由配置 */
import VueRouter from 'vue-router';
import Vue from 'vue';
/// import Util from './libs/util';
/// import store from 'src/vuex/store';
import Util from '@/libs/util.js';
import store from '@/vuex/store.js';
/// import { resolve } from 'core-js/library/es6/promise';

const routers = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    // component: function(resolve) {
    //   console.log(resolve, require)
    //   return require.ensure([], () => resolve(require('./views/mask/login/login_index.vue')), 'login');
    // }
    component: require('@/views/mask/login/login_index.vue').default
  },
  {
    path: '/reset-password',
    name: 'reset',
    meta: { title: '重置密码' },
    // component: function(resolve) {
    //   return require.ensure([], () => resolve(require('./views/login/reset.vue')), 'reset');
    // }
    component: require('@/views/login/reset.vue').default
  },
  {
    path: '/admin-login',
    name: 'admin-login',
    meta: { title: '超管登录' },
    // component: function(resolve) {
    //   return require.ensure([], () => resolve(require('./views/admin-login/index.vue')), 'admin-login');
    // }
    component: require('@/views/admin-login/index.vue').default
  },
  // 客户后台首页
  {
    // path: '/tryon-open',
    // name: 'tryon-open-home',
    path: '/mask',
    name: 'mask-home',
    meta: { title: '后台首页' },
    // component: function(resolve) {
    //   return require.ensure([], () => resolve(require('./views/index.vue'), 'home-view'));
    // }
    component: require('@/views/index.vue').default,
    children: [
      // admin权限
      {
        path: 'admin',
        name: 'admin-views',
        meta: { title: '超管后台首页' },
        // component: function(resolve) {
        //   return require.ensure([], () => resolve(require('./views/admin/views.vue')), 'admin-views');
        // }
        component: require('@/views/admin/views.vue').default,
        children: [
          {
            path: '/',
            name: 'admin-homepage',
            meta: { title: '后台首页' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/admin/home/views.vue')), 'admin-homepage');
            // }
            component: require('@/views/admin/home/views.vue').default
          },
          // 客户管理路由
          {
            path: 'customer',
            name: 'admin-customer',
            meta: { title: '客户管理' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/admin/customer/views.vue')), 'admin-customer');
            // }
            component: require('@/views/admin/customer/views.vue').default
          },
          // 服务价格路由
          {
            path: 'service-price',
            name: 'admin-service-price',
            meta: { title: '服务价格' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/admin/service-price/views.vue')), 'admin-service-price');
            // }
            component: require('@/views/admin/service-price/views.vue').default
          },
          // 订单管理路由
          {
            path: 'order',
            meta: { title: '订单管理' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/admin/order/views.vue')), 'admin-order');
            // },
            component: require('@/views/admin/order/views.vue').default,
            children: [
              {
                path: 'service',
                name: 'order-service',
                meta: { title: '服务订单' },
                // component: function(resolve) {
                //   return require.ensure([], () => resolve(require('./views/admin/order/service/views.vue')), 'order-service');
                // }
                component: require('@/views/admin/order/service/views.vue').default
              },
              {
                path: 'recharge',
                name: 'order-recharge',
                meta: { title: '充值订单' },
                // component: function(resolve) {
                //   return require.ensure([], () => resolve(require('./views/admin/order/recharge/views.vue')), 'order-recharge');
                // }
                component: require('@/views/admin/order/recharge/views.vue').default
              },
              {
                path: 'flow',
                name: 'order-flow',
                meta: { title: '资金流水' },
                // component: function(resolve) {
                //   return require.ensure([], () => resolve(require('./views/admin/order/flow/views.vue')), 'order-flow');
                // }
                component: require('@/views/admin/order/flow/views.vue').default
              }
            ]
          },
          // 个人信息路由
          {
            path: 'userInfo',
            name: 'admin-userInfo',
            meta: { title: '个人信息' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/admin/userInfo/views.vue')), 'admin-userInfo');
            // }
            component: require('@/views/admin/userInfo/views.vue').default
          }
        ]
      },
      {
        // path: 'customer',
        path: 'console',
        meta: { title: '后台首页' },
        // component: function(resolve) {
        //   return require.ensure([], () => resolve(require('./views/customer/views.vue')), 'customer-views');
        // }
        component: require('@/views/customer/views.vue').default,
        children: [
          {
            path: '/',
            name: 'customer-homepage',
            meta: { title: '后台首页' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/customer/home/index.vue')), 'customer-homepage');
            // }
            // component: require('@/views/customer/home/index-old.vue').default
            component: require('@/views/mask/home/dashboard.vue').default
          },
          // 技术服务路由
          {
            path: 'secret-key',
            name: 'secret',
            meta: { title: '密钥管理' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/customer/secret-key/views.vue')), 'secret-key');
            // }
            // component: require('@/views/customer/secret-key/views-old.vue').default
            component: require('@/views/customer/secret-key/views-new.vue').default
          },
          // 微信小程序页面设置
          {
            path: 'wxset',
            name: 'wxset',
            meta: { title: '小程序管理' },
            component: require('@/views/mask/wxset/wxset_index.vue').default,
            children: [
              {
                path: 'home',
                name: 'home',
                meta: { title: '首页' },
                component: require('@/views/mask/wxset/wxset_home.vue').default
              },
              {
                path: 'knowledge',
                name: 'knowledge',
                meta: { title: '口罩小知识' },
                component: require('@/views/mask/wxset/wxset_knowledge.vue').default
              },
              {
                path: 'capture_notice',
                name: 'capture_notice',
                meta: { title: '人脸采集须知' },
                component: require('@/views/mask/wxset/wxset_capture_notice.vue').default
              },
              {
                path: 'me',
                name: 'me',
                meta: { title: '我的' },
                component: require('@/views/mask/wxset/wxset_me.vue').default
              }
            ]
          },
          // 素材管理路由
          {
            path: 'material',
            name: 'material',
            meta: { title: '素材管理' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/customer/material/views.vue')), 'material');
            // }
            component: require('@/views/customer/material/views.vue').default,
            children: [
              {
                path: '2D',
                name: 'material-2D',
                meta: { title: '2D素材' },
                // component: function(resolve) {
                //   return require.ensure([], () => resolve(require('./views/customer/material/2D/views.vue')), 'material-2D');
                // }
                // component: require('@/views/customer/material/2D/views-old.vue').default
                component: require('@/views/customer/material/2D/views-new.vue').default
              },
              {
                path: '3D',
                name: 'material-3D',
                meta: { title: '3D素材' },
                // component: function(resolve) {
                //   return require.ensure([], () => resolve(require('./views/customer/material/3D/views.vue')), 'material-3D');
                // }
                // component: require('@/views/customer/material/3D/views-old.vue').default
                component: require('@/views/customer/material/3D/views-new.vue').default
              }
            ]
          },
          // 服务订单路由
          {
            path: 'order',
            name: 'order',
            meta: { title: '服务订单' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/customer/order/views.vue')), 'order');
            // }
            component: require('@/views/customer/order/views.vue').default
          },
          // 财务管理路由
          {
            path: 'finance',
            name: 'finance',
            // meta: { title: '财务管理' },
            meta: { title: '数据管理' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/customer/finance/views.vue')), 'finance');
            // }
            // component: require('@/views/customer/finance/views-old.vue').default,
            component: require('@/views/customer/finance/views-new.vue').default
          },
          // 用户管理路由
          {
            path: 'user',
            name: 'user',
            // meta: { title: '用户管理' },
            meta: { title: '用户管理' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/customer/finance/views.vue')), 'finance');
            // }
            // component: require('@/views/customer/finance/views-old.vue').default,
            component: require('@/views/mask/wxuser/wxuser_index.vue').default
          },
          // 推荐管理路由
          {
            path: 'analysis',
            name: 'analysis',
            // meta: { title: '推荐管理' },
            meta: { title: '推荐管理' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/customer/finance/views.vue')), 'finance');
            // }
            // component: require('@/views/customer/finance/views-old.vue').default,
            component: require('@/views/mask/analysis/analysis-index.vue').default
          },
          // 充值服务路由
          {
            path: 'recharge',
            name: 'recharge',
            meta: { title: '充值服务' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/customer/recharge/views.vue')), 'recharge');
            // }
            component: require('@/views/customer/recharge/views.vue').default
          },
          // 个人信息路由
          {
            // path: 'userInfo',
            path: 'info',
            name: 'userInfo',
            meta: { title: '登录信息' },
            // component: function(resolve) {
            //   return require.ensure([], () => resolve(require('./views/customer/userInfo/views.vue')), 'userInfo');
            // }
            // component: require('@/views/customer/userInfo/views-old.vue').default
            component: require('@/views/mask/info/info_index.vue').default
          }
        ]
      }
    ]
  },
  /** 本地测试路由 */
  {
    path: '/v-debug',
    children: [
      {
        path: '/',
        name: 'debug',
        meta: { title: 'debug测试' },
        // component: (resolve) => require.ensure([], () => resolve(require('./views/debug/vuex.vue')), 'debug-vuex')
        component: require('@/views/debug/vuex.vue').default
      },
      {
        path: 'example',
        name: 'example',
        meta: { title: 'example测试' },
        // component: (resolve) => require.ensure([], () => resolve(require('./views/debug/example.vue')), 'example')
        component: require('@/views/debug/example.vue').default
      }
    ]
  },
  {
    path: '*',
    redirect: { name: 'mask-home' }
  }
];

// 路由配置
const RouterConfig = {
  // mode: 'history',
  mode: 'hash',
  routes: routers
};

const router = new VueRouter(RouterConfig);

router.beforeEach(beforeEachRoute);
router.afterEach(afterEachRoute);

let login_page_name = '';

/**
 * 路由前件
 *
 * @param to
 * @param from
 * @param next
 */
function beforeEachRoute(to, from, next) {
  const to_path = to.fullPath;
  // element-ui show loading
  Vue.prototype.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  if (to_path.split('/').indexOf('admin') !== -1) {
    login_page_name = 'admin-login'
  } else {
    login_page_name = 'login';
  }
  if (shouldLogOut(to)) return redirectToLoginPage(from, next);
  if (to.name !== 'login' && to.name !== 'reset') {
    checkUrl(from, to);
  }
  // reset title
  Util.title(to.meta.title);
  next();
}

/**
 * 保存访问的路由地址
 * @param  {Object} to {路由的to}
 * @return {undefined}
 */
function checkUrl(from, to) {
  store.commit('menu/setSelectedMenu', to.path);
}

/** 如果不是登录页面，则判断是否存在用户的token，不然就重新跳转会login页面 */
function shouldLogOut(to) {
  return to.name !== 'login' && to.name !== 'admin-login' && to.name !== 'reset' && !store.state.userToken.user_token
}

/** 重定向到登录页面 */
function redirectToLoginPage(from, next) {
  Vue.prototype.$message({
    type: 'error',
    message: '没有登录权限！即将为您返回登录页面'
  });
  setTimeout(function() {
    if (from.name === login_page_name) {
      Vue.prototype.$loading().close();
    } else {
      next({
        replace: true,
        name: login_page_name
      })
    }
  }, 3000);
}

/** 路由后件处理 */
function afterEachRoute() {
  Vue.prototype.$loading().close();
  window.scrollTo(0, 0);
}

export default router;
