/* 财务管理主视图 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>
  <div class="v-customer-finance-views">
    <el-tabs v-model="tab_name" type="card" @tab-click="handleClick">
      <el-tab-pane label="API数据" name="API数据">
        <search-form @search="confirmSearch"></search-form>
        <flow-index :data="financeFlow"
                    :loading="flow_list_loading"></flow-index>
        <br>
        <el-pagination  align="center"
                        background
                        @size-change="perPageChangeByFinanceFlow"
                        @current-change="pageChangeByFinanceFlow"
                        :current-page.sync="cash_flows.pageNo"
                        :page-size="pageQueryInfoByFinanceFlow.pageSize"
                        layout="sizes, prev, pager, next"
                        :total="cash_flows.total">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="流水数据" name="流水数据">
        <cost-index :data="balanceFlow"
                    :loading="balance_list_loading"></cost-index>
        <br>
        <el-pagination  align="center"
                        background
                        @size-change="perPageChangeByBalanceFlow"
                        @current-change="pageChangeByBalanceFlow"
                        :current-page.sync="balance_flows.pageNo"
                        :page-size="pageQueryInfoByBalanceFlow.pageSize"
                        layout="sizes, prev, pager, next"
                        :total="balance_flows.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import APIs from '../../../APIs';
import flowIndex from './index-new';
import costIndex from './cost-index';
import searchForm from './search-form-new';
import { mapGetters } from 'vuex';
export default {
  name: 'finance-views',
  data: function() {
    return {
      tab_name: 'API数据',
      pageQueryInfoByFinanceFlow: {
        pageNo: 1,
        pageSize: 20,
        balance_type: '',
        time_range: []
      },
      pageSearchInfoByFinanceFlow: {
        pageNo: 1,
        pageSize: 20
      }, // 分页信息
      pageQueryInfoByBalanceFlow: {
        pageNo: 1,
        pageSize: 20
      },
      pageSearchInfoByBalanceFlow: {
        pageNo: 1,
        pageSize: 20
      },
      flow_list_loading: false, // 财务流水loading
      cash_flows: {}, // 财务流水
      balance_list_loading: false,
      balance_flows: {}
    }
  },
  components: {
    searchForm,
    flowIndex,
    costIndex
  },
  mounted () {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    this.pageQueryInfoByFinanceFlow.time_range = [this.dataToString(start), this.dataToString(end)];
    this.fetchFinanceFlow(this.pageQueryInfoByFinanceFlow);
  },
  computed: {
    ...mapGetters('userToken', ['getToken']),
    financeFlow() {
      return this.cash_flows.list || [];
    },
    balanceFlow() {
      return this.balance_flows.list || [];
    }
  },
  methods: {
    dataToString (date) {
      let year = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      if (month.length === 1) {
        month = '0' + month;
      }
      if (day.length === 1) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    },
    handleClick(tab) {
      if (tab.name === '流水数据') {
        this.fetchBalanceFlow(this.pageQueryInfoByBalanceFlow);
      } else {
        this.fetchFinanceFlow(this.pageQueryInfoByFinanceFlow);
      }
    },
    /** 页码改变 */
    pageChangeByFinanceFlow(pageNo) {
      this.pageSearchInfoByFinanceFlow.pageNo = pageNo;
      this.pageSearchInfoByFinanceFlow.pageSize = this.pageQueryInfoByFinanceFlow.pageSize;
      this.fetchFinanceFlow(this.pageSearchInfoByFinanceFlow);
    },
    /** 每页显示数改变 */
    perPageChangeByFinanceFlow(pageSize) {
      this.pageQueryInfoByFinanceFlow.pageSize = pageSize;
      this.pageSearchInfoByFinanceFlow.pageSize = pageSize;
      this.fetchFinanceFlow(this.pageSearchInfoByFinanceFlow);
    },
    pageChangeByBalanceFlow(pageNo) {
      this.pageSearchInfoByBalanceFlow.pageNo = pageNo;
      this.pageSearchInfoByBalanceFlow.pageSize = this.pageQueryInfoByBalanceFlow.pageSize;
      this.fetchBalanceFlow(this.pageSearchInfoByBalanceFlow);
    },
    perPageChangeByBalanceFlow(pageSize) {
      this.pageQueryInfoByBalanceFlow.pageSize = pageSize;
      this.pageSearchInfoByBalanceFlow.pageSize = pageSize;
      this.fetchBalanceFlow(this.pageSearchInfoByBalanceFlow);
    },
    /** 确认搜索 */
    confirmSearch({ balance_type, time_range }) {
      this.pageQueryInfoByFinanceFlow.balance_type = balance_type || '';
      this.pageQueryInfoByFinanceFlow.time_range = time_range || [];
      this.fetchFinanceFlow(this.pageQueryInfoByFinanceFlow);
    },
    /** 财务列表 */
    fetchFinanceFlow(param) {
      const self = this;
      self.flow_list_loading = true;
      let formData = new FormData();
      formData.append('token', self.getToken);
      if (self.pageQueryInfoByFinanceFlow.balance_type === 2) {
        formData.append('request_type', 'tryon_g2d');
      } else if (self.pageQueryInfoByFinanceFlow.balance_type === 3) {
        formData.append('request_type', 'tryon_g3d');
      }
      if (self.pageQueryInfoByFinanceFlow.time_range !== null && self.pageQueryInfoByFinanceFlow.time_range.length === 2) {
        formData.append('time_start', self.pageQueryInfoByFinanceFlow.time_range[0]);
        formData.append('time_end', self.pageQueryInfoByFinanceFlow.time_range[1]);
      }
      formData.append('pageNo', param.pageNo);
      formData.append('pageSize', param.pageSize);
      self.$http.post(APIs.ORDER_DATA_FLOW, formData)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.error_message);
          // console.log(data);
          self.cash_flows = data.data;
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.flow_list_loading = false })
    },
    fetchBalanceFlow(param) {
      const self = this;
      self.balance_list_loading = true;
      let formData = new FormData();
      formData.append('token', self.getToken);
      formData.append('pageNo', param.pageNo);
      formData.append('pageSize', param.pageSize);
      self.$http.post(APIs.ORDER_DATA_BALANCE, formData)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.error_message);
          // console.log(data);
          self.balance_flows = data.data;
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.balance_list_loading = false })
    }
  }
}
</script>