/**
 var fileName1 = getuuid(8, 12)
 var fileName2 = getTimeId(8, 12)
 console.log(fileName1, 'fileName1', fileName2, 'fileName2')
 */
/**
 * 生成唯一文件名
 * @param {Number} len 生成的文件名长度
 * @param {Number} radix 指定基数
 */
export function getUuid(len, radix) {
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  let uuid = []
  let i = 0
  radix = radix || chars.length
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
  } else {
    let r
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('')
}
/**
 * 生成唯一文件名 时间戳 + 随机数
 * @param {Number} len 生成的文件名长度
 * @param {Number} radix 指定基数
 */
export function getTimeId(len, radix) {
  if (len) {
    const time = new Date().getTime()
    const uuid = getUuid(len, radix)
    return `${time}${uuid}`
  } else {
    console.log('请输入长度')
  }
}